import PubNub from 'pubnub'
import { PubNubConsumer, PubNubProvider } from 'pubnub-react'
import { useEffect, useState } from 'react'

let pubnubClient = new PubNub({
  publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
  subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
  uuid: import.meta.env.VITE_PUBNUB_UUID,
})

export const RedPushProvider = (props: any) => (
  <PubNubProvider client={pubnubClient}>{props.children}</PubNubProvider>
)
export const RedPushConsumer = (props: any) => (
  <PubNubConsumer>{() => props.children}</PubNubConsumer>
)

export const useRedPush = (uuid?: string) => {
  const [redChanel, setRedChanel] = useState<any[]>([])
  // let pubnubClient: any;

  useEffect(() => {
    pubnubClient = new PubNub({
      publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
      subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
      uuid: uuid || import.meta.env.VITE_PUBNUB_UUID,
    })
  }, [uuid])

  useEffect(() => {
    return () => {
      pubnubClient && pubnubClient.unsubscribeAll();
    }
  }, [])

  const filterExpression = "entityId == '123'";
  const pusher = {
    subscribe: (channels: any[], callback: any) => {
      if (JSON.stringify(redChanel) != JSON.stringify(channels)) {
        pubnubClient && pubnubClient.addListener({
          message: (data: any) => {
            if (channels.includes(data.subscribedChannel)) callback(data)
          },
        })
        // filterExpression && pubnubClient.setFilterExpression(filterExpression)
        pubnubClient && pubnubClient.subscribe({ channels })
        setRedChanel(channels)
      }
    },
    unsubscribe: (channels: any, callback?: any) => {
      pubnubClient && pubnubClient.removeListener({
        message: (data: any) => {
          if (channels.includes(data.channel)) callback(data)
        },
      });
      pubnubClient && pubnubClient.unsubscribe({channels});
    },
    unsubscribeAll: ()=> {
      pubnubClient && pubnubClient.unsubscribeAll()
    },
    publish: (payload: any)=> {
      pubnubClient && pubnubClient.publish(payload)
    }
  }
  return pusher
}
