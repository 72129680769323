export const vitalSignsEcgData = {
  xValues: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
    218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232,
    233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
    248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
    263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277,
    278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292,
    293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307,
    308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322,
    323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337,
    338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352,
    353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366, 367,
    368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380, 381, 382,
    383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397,
    398, 399, 400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412,
    413, 414, 415, 416, 417, 418, 419, 420, 421, 422, 423, 424, 425, 426, 427,
    428, 429, 430, 431, 432, 433, 434, 435, 436, 437, 438, 439, 440, 441, 442,
    443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457,
    458, 459, 460, 461, 462, 463, 464, 465, 466, 467, 468, 469, 470, 471, 472,
    473, 474, 475, 476, 477, 478, 479, 480, 481, 482, 483, 484, 485, 486, 487,
    488, 489, 490, 491, 492, 493, 494, 495, 496, 497, 498, 499, 500, 501, 502,
    503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514, 515, 516, 517,
    518, 519, 520, 521, 522, 523, 524, 525, 526, 527, 528, 529, 530, 531, 532,
    533, 534, 535, 536, 537, 538, 539, 540, 541, 542, 543, 544, 545, 546, 547,
    548, 549, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559, 560, 561, 562,
    563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577,
    578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 589, 590, 591, 592,
    593, 594, 595, 596, 597, 598, 599, 600, 601, 602, 603, 604, 605, 606, 607,
    608, 609, 610, 611, 612, 613, 614, 615, 616, 617, 618, 619, 620, 621, 622,
    623, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634, 635, 636, 637,
    638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 649, 650, 651, 652,
    653, 654, 655, 656, 657, 658, 659, 660, 661, 662, 663, 664, 665, 666, 667,
    668, 669, 670, 671, 672, 673, 674, 675, 676, 677, 678, 679, 680, 681, 682,
    683, 684, 685, 686, 687, 688, 689, 690, 691, 692, 693, 694, 695, 696, 697,
    698, 699, 700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 710, 711, 712,
    713, 714, 715, 716, 717, 718, 719, 720, 721, 722, 723, 724, 725, 726, 727,
    728, 729, 730, 731, 732, 733, 734, 735, 736, 737, 738, 739, 740, 741, 742,
    743, 744, 745, 746, 747, 748, 749, 750, 751, 752, 753, 754, 755, 756, 757,
    758, 759, 760, 761, 762, 763, 764, 765, 766, 767, 768, 769, 770, 771, 772,
    773, 774, 775, 776, 777, 778, 779, 780, 781, 782, 783, 784, 785, 786, 787,
    788, 789, 790, 791, 792, 793, 794, 795, 796, 797, 798, 799, 800, 801, 802,
    803, 804, 805, 806, 807, 808, 809, 810, 811, 812, 813, 814, 815, 816, 817,
    818, 819, 820, 821, 822, 823, 824, 825, 826, 827, 828, 829, 830, 831, 832,
    833, 834, 835, 836, 837, 838, 839, 840, 841, 842, 843, 844, 845, 846, 847,
    848, 849, 850, 851, 852, 853, 854, 855, 856, 857, 858, 859, 860, 861, 862,
    863, 864, 865, 866, 867, 868, 869, 870, 871, 872, 873, 874, 875, 876, 877,
    878, 879, 880, 881, 882, 883, 884, 885, 886, 887, 888, 889, 890, 891, 892,
    893, 894, 895, 896, 897, 898, 899, 900, 901, 902, 903, 904, 905, 906, 907,
    908, 909, 910, 911, 912, 913, 914, 915, 916, 917, 918, 919, 920, 921, 922,
    923, 924, 925, 926, 927, 928, 929, 930, 931, 932, 933, 934, 935, 936, 937,
    938, 939, 940, 941, 942, 943, 944, 945, 946, 947, 948, 949, 950, 951, 952,
    953, 954, 955, 956, 957, 958, 959, 960, 961, 962, 963, 964, 965, 966, 967,
    968, 969, 970, 971, 972, 973, 974, 975, 976, 977, 978, 979, 980, 981, 982,
    983, 984, 985, 986, 987, 988, 989, 990, 991, 992, 993, 994, 995, 996, 997,
    998, 999, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010,
    1011, 1012, 1013, 1014, 1015, 1016, 1017, 1018, 1019, 1020, 1021, 1022,
    1023, 1024, 1025, 1026, 1027, 1028, 1029, 1030, 1031, 1032, 1033, 1034,
    1035, 1036, 1037, 1038, 1039, 1040, 1041, 1042, 1043, 1044, 1045, 1046,
    1047, 1048, 1049, 1050, 1051, 1052, 1053, 1054, 1055, 1056, 1057, 1058,
    1059, 1060, 1061, 1062, 1063, 1064, 1065, 1066, 1067, 1068, 1069, 1070,
    1071, 1072, 1073, 1074, 1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082,
    1083, 1084, 1085, 1086, 1087, 1088, 1089, 1090, 1091, 1092, 1093, 1094,
    1095, 1096, 1097, 1098, 1099, 1100, 1101, 1102, 1103, 1104, 1105, 1106,
    1107, 1108, 1109, 1110, 1111, 1112, 1113, 1114, 1115, 1116, 1117, 1118,
    1119, 1120, 1121, 1122, 1123, 1124, 1125, 1126, 1127, 1128, 1129, 1130,
    1131, 1132, 1133, 1134, 1135, 1136, 1137, 1138, 1139, 1140, 1141, 1142,
    1143, 1144, 1145, 1146, 1147, 1148, 1149, 1150, 1151, 1152, 1153, 1154,
    1155, 1156, 1157, 1158, 1159, 1160, 1161, 1162, 1163, 1164, 1165, 1166,
    1167, 1168, 1169, 1170, 1171, 1172, 1173, 1174, 1175, 1176, 1177, 1178,
    1179, 1180, 1181, 1182, 1183, 1184, 1185, 1186, 1187, 1188, 1189, 1190,
    1191, 1192, 1193, 1194, 1195, 1196, 1197, 1198, 1199, 1200, 1201, 1202,
    1203, 1204, 1205, 1206, 1207, 1208, 1209, 1210, 1211, 1212, 1213, 1214,
    1215, 1216, 1217, 1218, 1219, 1220, 1221, 1222, 1223, 1224, 1225, 1226,
    1227, 1228, 1229, 1230, 1231, 1232, 1233, 1234, 1235, 1236, 1237, 1238,
    1239, 1240, 1241, 1242, 1243, 1244, 1245, 1246, 1247, 1248, 1249, 1250,
    1251, 1252, 1253, 1254, 1255, 1256, 1257, 1258, 1259, 1260, 1261, 1262,
    1263, 1264, 1265, 1266, 1267, 1268, 1269, 1270, 1271, 1272, 1273, 1274,
    1275, 1276, 1277, 1278, 1279, 1280, 1281, 1282, 1283, 1284, 1285, 1286,
    1287, 1288, 1289, 1290, 1291, 1292, 1293, 1294, 1295, 1296, 1297, 1298,
    1299, 1300, 1301, 1302, 1303, 1304, 1305, 1306, 1307, 1308, 1309, 1310,
    1311, 1312, 1313, 1314, 1315, 1316, 1317, 1318, 1319, 1320, 1321, 1322,
    1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1331, 1332, 1333, 1334,
    1335, 1336, 1337, 1338, 1339, 1340, 1341, 1342, 1343, 1344, 1345, 1346,
    1347, 1348, 1349, 1350, 1351, 1352, 1353, 1354, 1355, 1356, 1357, 1358,
    1359, 1360, 1361, 1362, 1363, 1364, 1365, 1366, 1367, 1368, 1369, 1370,
    1371, 1372, 1373, 1374, 1375, 1376, 1377, 1378, 1379, 1380, 1381, 1382,
    1383, 1384, 1385, 1386, 1387, 1388, 1389, 1390, 1391, 1392, 1393, 1394,
    1395, 1396, 1397, 1398, 1399, 1400, 1401, 1402, 1403, 1404, 1405, 1406,
    1407, 1408, 1409, 1410, 1411, 1412, 1413, 1414, 1415, 1416, 1417, 1418,
    1419, 1420, 1421, 1422, 1423, 1424, 1425, 1426, 1427, 1428, 1429, 1430,
    1431, 1432, 1433, 1434, 1435, 1436, 1437, 1438, 1439, 1440, 1441, 1442,
    1443, 1444, 1445, 1446, 1447, 1448, 1449, 1450, 1451, 1452, 1453, 1454,
    1455, 1456, 1457, 1458, 1459, 1460, 1461, 1462, 1463, 1464, 1465, 1466,
    1467, 1468, 1469, 1470, 1471, 1472, 1473, 1474, 1475, 1476, 1477, 1478,
    1479, 1480, 1481, 1482, 1483, 1484, 1485, 1486, 1487, 1488, 1489, 1490,
    1491, 1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502,
    1503, 1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514,
    1515, 1516, 1517, 1518, 1519, 1520, 1521, 1522, 1523, 1524, 1525, 1526,
    1527, 1528, 1529, 1530, 1531, 1532, 1533, 1534, 1535, 1536, 1537, 1538,
    1539, 1540, 1541, 1542, 1543, 1544, 1545, 1546, 1547, 1548, 1549, 1550,
    1551, 1552, 1553, 1554, 1555, 1556, 1557, 1558, 1559, 1560, 1561, 1562,
    1563, 1564, 1565, 1566, 1567, 1568, 1569, 1570, 1571, 1572, 1573, 1574,
    1575, 1576, 1577, 1578, 1579, 1580, 1581, 1582, 1583, 1584, 1585, 1586,
    1587, 1588, 1589, 1590, 1591, 1592, 1593, 1594, 1595, 1596, 1597, 1598,
    1599, 1600, 1601, 1602, 1603, 1604, 1605, 1606, 1607, 1608, 1609, 1610,
    1611, 1612, 1613, 1614, 1615, 1616, 1617, 1618, 1619, 1620, 1621, 1622,
    1623, 1624, 1625, 1626, 1627, 1628, 1629, 1630, 1631, 1632, 1633, 1634,
    1635, 1636, 1637, 1638, 1639, 1640, 1641, 1642, 1643, 1644, 1645, 1646,
    1647, 1648, 1649, 1650, 1651, 1652, 1653, 1654, 1655, 1656, 1657, 1658,
    1659, 1660, 1661, 1662, 1663, 1664, 1665, 1666, 1667, 1668, 1669, 1670,
    1671, 1672, 1673, 1674, 1675, 1676, 1677, 1678, 1679, 1680, 1681, 1682,
    1683, 1684, 1685, 1686, 1687, 1688, 1689, 1690, 1691, 1692, 1693, 1694,
    1695, 1696, 1697, 1698, 1699, 1700, 1701, 1702, 1703, 1704, 1705, 1706,
    1707, 1708, 1709, 1710, 1711, 1712, 1713, 1714, 1715, 1716, 1717, 1718,
    1719, 1720, 1721, 1722, 1723, 1724, 1725, 1726, 1727, 1728, 1729, 1730,
    1731, 1732, 1733, 1734, 1735, 1736, 1737, 1738, 1739, 1740, 1741, 1742,
    1743, 1744, 1745, 1746, 1747, 1748, 1749, 1750, 1751, 1752, 1753, 1754,
    1755, 1756, 1757, 1758, 1759, 1760, 1761, 1762, 1763, 1764, 1765, 1766,
    1767, 1768, 1769, 1770, 1771, 1772, 1773, 1774, 1775, 1776, 1777, 1778,
    1779, 1780, 1781, 1782, 1783, 1784, 1785, 1786, 1787, 1788, 1789, 1790,
    1791, 1792, 1793, 1794, 1795, 1796, 1797, 1798, 1799, 1800, 1801, 1802,
    1803, 1804, 1805, 1806, 1807, 1808, 1809, 1810, 1811, 1812, 1813, 1814,
    1815, 1816, 1817, 1818, 1819, 1820, 1821, 1822, 1823, 1824, 1825, 1826,
    1827, 1828, 1829, 1830, 1831, 1832, 1833, 1834, 1835, 1836, 1837, 1838,
    1839, 1840, 1841, 1842, 1843, 1844, 1845, 1846, 1847, 1848, 1849, 1850,
    1851, 1852, 1853, 1854, 1855, 1856, 1857, 1858, 1859, 1860, 1861, 1862,
    1863, 1864, 1865, 1866, 1867, 1868, 1869, 1870, 1871, 1872, 1873, 1874,
    1875, 1876, 1877, 1878, 1879, 1880, 1881, 1882, 1883, 1884, 1885, 1886,
    1887, 1888, 1889, 1890, 1891, 1892, 1893, 1894, 1895, 1896, 1897, 1898,
    1899, 1900, 1901, 1902, 1903, 1904, 1905, 1906, 1907, 1908, 1909, 1910,
    1911, 1912, 1913, 1914, 1915, 1916, 1917, 1918, 1919, 1920, 1921, 1922,
    1923, 1924, 1925, 1926, 1927, 1928, 1929, 1930, 1931, 1932, 1933, 1934,
    1935, 1936, 1937, 1938, 1939, 1940, 1941, 1942, 1943, 1944, 1945, 1946,
    1947, 1948, 1949, 1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958,
    1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970,
    1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982,
    1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994,
    1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006,
    2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
    2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
    2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042,
    2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054,
    2055, 2056, 2057, 2058, 2059, 2060, 2061, 2062, 2063, 2064, 2065, 2066,
    2067, 2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078,
    2079, 2080, 2081, 2082, 2083, 2084, 2085, 2086, 2087, 2088, 2089, 2090,
    2091, 2092, 2093, 2094, 2095, 2096, 2097, 2098, 2099, 2100, 2101, 2102,
    2103, 2104, 2105, 2106, 2107, 2108, 2109, 2110, 2111, 2112, 2113, 2114,
    2115, 2116, 2117, 2118, 2119, 2120, 2121, 2122, 2123, 2124, 2125, 2126,
    2127, 2128, 2129, 2130, 2131, 2132, 2133, 2134, 2135, 2136, 2137, 2138,
    2139, 2140, 2141, 2142, 2143, 2144, 2145, 2146, 2147, 2148, 2149, 2150,
    2151, 2152, 2153, 2154, 2155, 2156, 2157, 2158, 2159, 2160, 2161, 2162,
    2163, 2164, 2165, 2166, 2167, 2168, 2169, 2170, 2171, 2172, 2173, 2174,
    2175, 2176, 2177, 2178, 2179, 2180, 2181, 2182, 2183, 2184, 2185, 2186,
    2187, 2188, 2189, 2190, 2191, 2192, 2193, 2194, 2195, 2196, 2197, 2198,
    2199, 2200, 2201, 2202, 2203, 2204, 2205, 2206, 2207, 2208, 2209, 2210,
    2211, 2212, 2213, 2214, 2215, 2216, 2217, 2218, 2219, 2220, 2221, 2222,
    2223, 2224, 2225, 2226, 2227, 2228, 2229, 2230, 2231, 2232, 2233, 2234,
    2235, 2236, 2237, 2238, 2239, 2240, 2241, 2242, 2243, 2244, 2245, 2246,
    2247, 2248, 2249, 2250, 2251, 2252, 2253, 2254, 2255, 2256, 2257, 2258,
    2259, 2260, 2261, 2262, 2263, 2264, 2265, 2266, 2267, 2268, 2269, 2270,
    2271, 2272, 2273, 2274, 2275, 2276, 2277, 2278, 2279, 2280, 2281, 2282,
    2283, 2284, 2285, 2286, 2287, 2288, 2289, 2290, 2291, 2292, 2293, 2294,
    2295, 2296, 2297, 2298, 2299, 2300, 2301, 2302, 2303, 2304, 2305, 2306,
    2307, 2308, 2309, 2310, 2311, 2312, 2313, 2314, 2315, 2316, 2317, 2318,
    2319, 2320, 2321, 2322, 2323, 2324, 2325, 2326, 2327, 2328, 2329, 2330,
    2331, 2332, 2333, 2334, 2335, 2336, 2337, 2338, 2339, 2340, 2341, 2342,
    2343, 2344, 2345, 2346, 2347, 2348, 2349, 2350, 2351, 2352, 2353, 2354,
    2355, 2356, 2357, 2358, 2359, 2360, 2361, 2362, 2363, 2364, 2365, 2366,
    2367, 2368, 2369, 2370, 2371, 2372, 2373, 2374, 2375, 2376, 2377, 2378,
    2379, 2380, 2381, 2382, 2383, 2384, 2385, 2386, 2387, 2388, 2389, 2390,
    2391, 2392, 2393, 2394, 2395, 2396, 2397, 2398, 2399, 2400, 2401, 2402,
    2403, 2404, 2405, 2406, 2407, 2408, 2409, 2410, 2411, 2412, 2413, 2414,
    2415, 2416, 2417, 2418, 2419, 2420, 2421, 2422, 2423, 2424, 2425, 2426,
    2427, 2428, 2429, 2430, 2431, 2432, 2433, 2434, 2435, 2436, 2437, 2438,
    2439, 2440, 2441, 2442, 2443, 2444, 2445, 2446, 2447, 2448, 2449, 2450,
    2451, 2452, 2453, 2454, 2455, 2456, 2457, 2458, 2459, 2460, 2461, 2462,
    2463, 2464, 2465, 2466, 2467, 2468, 2469, 2470, 2471, 2472, 2473, 2474,
    2475, 2476, 2477, 2478, 2479, 2480, 2481, 2482, 2483, 2484, 2485, 2486,
    2487, 2488, 2489, 2490, 2491, 2492, 2493, 2494, 2495, 2496, 2497, 2498,
    2499, 2500, 2501, 2502, 2503, 2504, 2505, 2506, 2507, 2508, 2509, 2510,
    2511, 2512, 2513, 2514, 2515, 2516, 2517, 2518, 2519, 2520, 2521, 2522,
    2523, 2524, 2525, 2526, 2527, 2528, 2529, 2530, 2531, 2532, 2533, 2534,
    2535, 2536, 2537, 2538, 2539, 2540, 2541, 2542, 2543, 2544, 2545, 2546,
    2547, 2548, 2549, 2550, 2551, 2552, 2553, 2554, 2555, 2556, 2557, 2558,
    2559, 2560, 2561, 2562, 2563, 2564, 2565, 2566, 2567, 2568, 2569, 2570,
    2571, 2572, 2573, 2574, 2575, 2576, 2577, 2578, 2579, 2580, 2581, 2582,
    2583, 2584, 2585, 2586, 2587, 2588, 2589, 2590, 2591, 2592, 2593, 2594,
    2595, 2596, 2597, 2598, 2599, 2600, 2601, 2602, 2603, 2604, 2605, 2606,
    2607, 2608, 2609, 2610, 2611, 2612, 2613, 2614, 2615, 2616, 2617, 2618,
    2619, 2620, 2621, 2622, 2623, 2624, 2625, 2626, 2627, 2628, 2629, 2630,
    2631, 2632, 2633, 2634, 2635, 2636, 2637, 2638, 2639, 2640, 2641, 2642,
    2643, 2644, 2645, 2646, 2647, 2648, 2649, 2650, 2651, 2652, 2653, 2654,
    2655, 2656, 2657, 2658, 2659, 2660, 2661, 2662, 2663, 2664, 2665, 2666,
    2667, 2668, 2669, 2670, 2671, 2672, 2673, 2674, 2675, 2676, 2677, 2678,
    2679, 2680, 2681, 2682, 2683, 2684, 2685, 2686, 2687, 2688, 2689, 2690,
    2691, 2692, 2693, 2694, 2695, 2696, 2697, 2698, 2699, 2700, 2701, 2702,
    2703, 2704, 2705, 2706, 2707, 2708, 2709, 2710, 2711, 2712, 2713, 2714,
    2715, 2716, 2717, 2718, 2719, 2720, 2721, 2722, 2723, 2724, 2725, 2726,
    2727, 2728, 2729, 2730, 2731, 2732, 2733, 2734, 2735, 2736, 2737, 2738,
    2739, 2740, 2741, 2742, 2743, 2744, 2745, 2746, 2747, 2748, 2749, 2750,
    2751, 2752, 2753, 2754, 2755, 2756, 2757, 2758, 2759, 2760, 2761, 2762,
    2763, 2764, 2765, 2766, 2767, 2768, 2769, 2770, 2771, 2772, 2773, 2774,
    2775, 2776, 2777, 2778, 2779, 2780, 2781, 2782, 2783, 2784, 2785, 2786,
    2787, 2788, 2789, 2790, 2791, 2792, 2793, 2794, 2795, 2796, 2797, 2798,
    2799, 2800, 2801, 2802, 2803, 2804, 2805, 2806, 2807, 2808, 2809, 2810,
    2811, 2812, 2813, 2814, 2815, 2816, 2817, 2818, 2819, 2820, 2821, 2822,
    2823, 2824, 2825, 2826, 2827, 2828, 2829, 2830, 2831, 2832, 2833, 2834,
    2835, 2836, 2837, 2838, 2839, 2840, 2841, 2842, 2843, 2844, 2845, 2846,
    2847, 2848, 2849, 2850, 2851, 2852, 2853, 2854, 2855, 2856, 2857, 2858,
    2859, 2860, 2861, 2862, 2863, 2864, 2865, 2866, 2867, 2868, 2869, 2870,
    2871, 2872, 2873, 2874, 2875, 2876, 2877, 2878, 2879, 2880, 2881, 2882,
    2883, 2884, 2885, 2886, 2887, 2888, 2889, 2890, 2891, 2892, 2893, 2894,
    2895, 2896, 2897, 2898, 2899, 2900, 2901, 2902, 2903, 2904, 2905, 2906,
    2907, 2908, 2909, 2910, 2911, 2912, 2913, 2914, 2915, 2916, 2917, 2918,
    2919, 2920, 2921, 2922, 2923, 2924, 2925, 2926, 2927, 2928, 2929, 2930,
    2931, 2932, 2933, 2934, 2935, 2936, 2937, 2938, 2939, 2940, 2941, 2942,
    2943, 2944, 2945, 2946, 2947, 2948, 2949, 2950, 2951, 2952, 2953, 2954,
    2955, 2956, 2957, 2958, 2959, 2960, 2961, 2962, 2963, 2964, 2965, 2966,
    2967, 2968, 2969, 2970, 2971, 2972, 2973, 2974, 2975, 2976, 2977, 2978,
    2979, 2980, 2981, 2982, 2983, 2984, 2985, 2986, 2987, 2988, 2989, 2990,
    2991, 2992, 2993, 2994, 2995, 2996, 2997, 2998, 2999, 3000, 3001, 3002,
    3003, 3004, 3005, 3006, 3007, 3008, 3009, 3010, 3011, 3012, 3013, 3014,
    3015, 3016, 3017, 3018, 3019, 3020, 3021, 3022, 3023, 3024, 3025, 3026,
    3027, 3028, 3029, 3030, 3031, 3032, 3033, 3034, 3035, 3036, 3037, 3038,
    3039, 3040, 3041, 3042, 3043, 3044, 3045, 3046, 3047, 3048, 3049, 3050,
    3051, 3052, 3053, 3054, 3055, 3056, 3057, 3058, 3059, 3060, 3061, 3062,
    3063, 3064, 3065, 3066, 3067, 3068, 3069, 3070, 3071, 3072, 3073, 3074,
    3075, 3076, 3077, 3078, 3079, 3080, 3081, 3082, 3083, 3084, 3085, 3086,
    3087, 3088, 3089, 3090, 3091, 3092, 3093, 3094, 3095, 3096, 3097, 3098,
    3099, 3100, 3101, 3102, 3103, 3104, 3105, 3106, 3107, 3108, 3109, 3110,
    3111, 3112, 3113, 3114, 3115, 3116, 3117, 3118, 3119, 3120, 3121, 3122,
    3123, 3124, 3125, 3126, 3127, 3128, 3129, 3130, 3131, 3132, 3133, 3134,
    3135, 3136, 3137, 3138, 3139, 3140, 3141, 3142, 3143, 3144, 3145, 3146,
    3147, 3148, 3149, 3150, 3151, 3152, 3153, 3154, 3155, 3156, 3157, 3158,
    3159, 3160, 3161, 3162, 3163, 3164, 3165, 3166, 3167, 3168, 3169, 3170,
    3171, 3172, 3173, 3174, 3175, 3176, 3177, 3178, 3179, 3180, 3181, 3182,
    3183, 3184, 3185, 3186, 3187, 3188, 3189, 3190, 3191, 3192, 3193, 3194,
    3195, 3196, 3197, 3198, 3199, 3200, 3201, 3202, 3203, 3204, 3205, 3206,
    3207, 3208, 3209, 3210, 3211, 3212, 3213, 3214, 3215, 3216, 3217, 3218,
    3219, 3220, 3221, 3222, 3223, 3224, 3225, 3226, 3227, 3228, 3229, 3230,
    3231, 3232, 3233, 3234, 3235, 3236, 3237, 3238, 3239, 3240, 3241, 3242,
    3243, 3244, 3245, 3246, 3247, 3248, 3249, 3250, 3251, 3252, 3253, 3254,
    3255, 3256, 3257, 3258, 3259, 3260, 3261, 3262, 3263, 3264, 3265, 3266,
    3267, 3268, 3269, 3270, 3271, 3272, 3273, 3274, 3275, 3276, 3277, 3278,
    3279, 3280, 3281, 3282, 3283, 3284, 3285, 3286, 3287, 3288, 3289, 3290,
    3291, 3292, 3293, 3294, 3295, 3296, 3297, 3298, 3299, 3300, 3301, 3302,
    3303, 3304, 3305, 3306, 3307, 3308, 3309, 3310, 3311, 3312, 3313, 3314,
    3315, 3316, 3317, 3318, 3319, 3320, 3321, 3322, 3323, 3324, 3325, 3326,
    3327, 3328, 3329, 3330, 3331, 3332, 3333, 3334, 3335, 3336, 3337, 3338,
    3339, 3340, 3341, 3342, 3343, 3344, 3345, 3346, 3347, 3348, 3349, 3350,
    3351, 3352, 3353, 3354, 3355, 3356, 3357, 3358, 3359, 3360, 3361, 3362,
    3363, 3364, 3365, 3366, 3367, 3368, 3369, 3370, 3371, 3372, 3373, 3374,
    3375, 3376, 3377, 3378, 3379, 3380, 3381, 3382, 3383, 3384, 3385, 3386,
    3387, 3388, 3389, 3390, 3391, 3392, 3393, 3394, 3395, 3396, 3397, 3398,
    3399, 3400, 3401, 3402, 3403, 3404, 3405, 3406, 3407, 3408, 3409, 3410,
    3411, 3412, 3413, 3414, 3415, 3416, 3417, 3418, 3419, 3420, 3421, 3422,
    3423, 3424, 3425, 3426, 3427, 3428, 3429, 3430, 3431, 3432, 3433, 3434,
    3435, 3436, 3437, 3438, 3439, 3440, 3441, 3442, 3443, 3444, 3445, 3446,
    3447, 3448, 3449, 3450, 3451, 3452, 3453, 3454, 3455, 3456, 3457, 3458,
    3459, 3460, 3461, 3462, 3463, 3464, 3465, 3466, 3467, 3468, 3469, 3470,
    3471, 3472, 3473, 3474, 3475, 3476, 3477, 3478, 3479, 3480, 3481, 3482,
    3483, 3484, 3485, 3486, 3487, 3488, 3489, 3490, 3491, 3492, 3493, 3494,
    3495, 3496, 3497, 3498, 3499, 3500, 3501, 3502, 3503, 3504, 3505, 3506,
    3507, 3508, 3509, 3510, 3511, 3512, 3513, 3514, 3515, 3516, 3517, 3518,
    3519, 3520, 3521, 3522, 3523, 3524, 3525, 3526, 3527, 3528, 3529, 3530,
    3531, 3532, 3533, 3534, 3535, 3536, 3537, 3538, 3539, 3540, 3541, 3542,
    3543, 3544, 3545, 3546, 3547, 3548, 3549, 3550, 3551, 3552, 3553, 3554,
    3555, 3556, 3557, 3558, 3559, 3560, 3561, 3562, 3563, 3564, 3565, 3566,
    3567, 3568, 3569, 3570, 3571, 3572, 3573, 3574, 3575, 3576, 3577, 3578,
    3579, 3580, 3581, 3582, 3583, 3584, 3585, 3586, 3587, 3588, 3589, 3590,
    3591, 3592, 3593, 3594, 3595, 3596, 3597, 3598, 3599, 3600, 3601, 3602,
    3603, 3604, 3605, 3606, 3607, 3608, 3609, 3610, 3611, 3612, 3613, 3614,
    3615, 3616, 3617, 3618, 3619, 3620, 3621, 3622, 3623, 3624, 3625, 3626,
    3627, 3628, 3629, 3630, 3631, 3632, 3633, 3634, 3635, 3636, 3637, 3638,
    3639, 3640, 3641, 3642, 3643, 3644, 3645, 3646, 3647, 3648, 3649, 3650,
    3651, 3652, 3653, 3654, 3655, 3656, 3657, 3658, 3659, 3660, 3661, 3662,
    3663, 3664, 3665, 3666, 3667, 3668, 3669, 3670, 3671, 3672, 3673, 3674,
    3675, 3676, 3677, 3678, 3679, 3680, 3681, 3682, 3683, 3684, 3685, 3686,
    3687, 3688, 3689, 3690, 3691, 3692, 3693, 3694, 3695, 3696, 3697, 3698,
    3699, 3700, 3701, 3702, 3703, 3704, 3705, 3706, 3707, 3708, 3709, 3710,
    3711, 3712, 3713, 3714, 3715, 3716, 3717, 3718, 3719, 3720, 3721, 3722,
    3723, 3724, 3725, 3726, 3727, 3728, 3729, 3730, 3731, 3732, 3733, 3734,
    3735, 3736, 3737, 3738, 3739, 3740, 3741, 3742, 3743, 3744, 3745, 3746,
    3747, 3748, 3749, 3750, 3751, 3752, 3753, 3754, 3755, 3756, 3757, 3758,
    3759, 3760, 3761, 3762, 3763, 3764, 3765, 3766, 3767, 3768, 3769, 3770,
    3771, 3772, 3773, 3774, 3775, 3776, 3777, 3778, 3779, 3780, 3781, 3782,
    3783, 3784, 3785, 3786, 3787, 3788, 3789, 3790, 3791, 3792, 3793, 3794,
    3795, 3796, 3797, 3798, 3799, 3800, 3801, 3802, 3803, 3804, 3805, 3806,
    3807, 3808, 3809, 3810, 3811, 3812, 3813, 3814, 3815, 3816, 3817, 3818,
    3819, 3820, 3821, 3822, 3823, 3824, 3825, 3826, 3827, 3828, 3829, 3830,
    3831, 3832, 3833, 3834, 3835, 3836, 3837, 3838, 3839, 3840, 3841, 3842,
    3843, 3844, 3845, 3846, 3847, 3848, 3849, 3850, 3851, 3852, 3853, 3854,
    3855, 3856, 3857, 3858, 3859, 3860, 3861, 3862, 3863, 3864, 3865, 3866,
    3867, 3868, 3869, 3870, 3871, 3872, 3873, 3874, 3875, 3876, 3877, 3878,
    3879, 3880, 3881, 3882, 3883, 3884, 3885, 3886, 3887, 3888, 3889, 3890,
    3891, 3892, 3893, 3894, 3895, 3896, 3897, 3898, 3899, 3900, 3901, 3902,
    3903, 3904, 3905, 3906, 3907, 3908, 3909, 3910, 3911, 3912, 3913, 3914,
    3915, 3916, 3917, 3918, 3919, 3920, 3921, 3922, 3923, 3924, 3925, 3926,
    3927, 3928, 3929, 3930, 3931, 3932, 3933, 3934, 3935, 3936, 3937, 3938,
    3939, 3940, 3941, 3942, 3943, 3944, 3945, 3946, 3947, 3948, 3949, 3950,
    3951, 3952, 3953, 3954, 3955, 3956, 3957, 3958, 3959, 3960, 3961, 3962,
    3963, 3964, 3965, 3966, 3967, 3968, 3969, 3970, 3971, 3972, 3973, 3974,
    3975, 3976, 3977, 3978, 3979, 3980, 3981, 3982, 3983, 3984, 3985, 3986,
    3987, 3988, 3989, 3990, 3991, 3992, 3993, 3994, 3995, 3996, 3997, 3998,
    3999, 4000, 4001, 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4009, 4010,
    4011, 4012, 4013, 4014, 4015, 4016, 4017, 4018, 4019, 4020, 4021, 4022,
    4023, 4024, 4025, 4026, 4027, 4028, 4029, 4030, 4031, 4032, 4033, 4034,
    4035, 4036, 4037, 4038, 4039, 4040, 4041, 4042, 4043, 4044, 4045, 4046,
    4047, 4048, 4049, 4050, 4051, 4052, 4053, 4054, 4055, 4056, 4057, 4058,
    4059, 4060, 4061, 4062, 4063, 4064, 4065, 4066, 4067, 4068, 4069, 4070,
    4071, 4072, 4073, 4074, 4075, 4076, 4077, 4078, 4079, 4080, 4081, 4082,
    4083, 4084, 4085, 4086, 4087, 4088, 4089, 4090, 4091, 4092, 4093, 4094,
    4095, 4096, 4097, 4098, 4099, 4100, 4101, 4102, 4103, 4104, 4105, 4106,
    4107, 4108, 4109, 4110, 4111, 4112, 4113, 4114, 4115, 4116, 4117, 4118,
    4119, 4120, 4121, 4122, 4123, 4124, 4125, 4126, 4127, 4128, 4129, 4130,
    4131, 4132, 4133, 4134, 4135, 4136, 4137, 4138, 4139, 4140, 4141, 4142,
    4143, 4144, 4145, 4146, 4147, 4148, 4149, 4150, 4151, 4152, 4153, 4154,
    4155, 4156, 4157, 4158, 4159, 4160, 4161, 4162, 4163, 4164, 4165, 4166,
    4167, 4168, 4169, 4170, 4171, 4172, 4173, 4174, 4175, 4176, 4177, 4178,
    4179, 4180, 4181, 4182, 4183, 4184, 4185, 4186, 4187, 4188, 4189, 4190,
    4191, 4192, 4193, 4194, 4195, 4196, 4197, 4198, 4199, 4200, 4201, 4202,
    4203, 4204, 4205, 4206, 4207, 4208, 4209, 4210, 4211, 4212, 4213, 4214,
    4215, 4216, 4217, 4218, 4219, 4220, 4221, 4222, 4223, 4224, 4225, 4226,
    4227, 4228, 4229, 4230, 4231, 4232, 4233, 4234, 4235, 4236, 4237, 4238,
    4239, 4240, 4241, 4242, 4243, 4244, 4245, 4246, 4247, 4248, 4249, 4250,
    4251, 4252, 4253, 4254, 4255, 4256, 4257, 4258, 4259, 4260, 4261, 4262,
    4263, 4264, 4265, 4266, 4267, 4268, 4269, 4270, 4271, 4272, 4273, 4274,
    4275, 4276, 4277, 4278, 4279, 4280, 4281, 4282, 4283, 4284, 4285, 4286,
    4287, 4288, 4289, 4290, 4291, 4292, 4293, 4294, 4295, 4296, 4297, 4298,
    4299, 4300, 4301, 4302, 4303, 4304, 4305, 4306, 4307, 4308, 4309, 4310,
    4311, 4312, 4313, 4314, 4315, 4316, 4317, 4318, 4319, 4320, 4321, 4322,
    4323, 4324, 4325, 4326, 4327, 4328, 4329, 4330, 4331, 4332, 4333, 4334,
    4335, 4336, 4337, 4338, 4339, 4340, 4341, 4342, 4343, 4344, 4345, 4346,
    4347, 4348, 4349, 4350, 4351, 4352, 4353, 4354, 4355, 4356, 4357, 4358,
    4359, 4360, 4361, 4362, 4363, 4364, 4365, 4366, 4367, 4368, 4369, 4370,
    4371, 4372, 4373, 4374, 4375, 4376, 4377, 4378, 4379, 4380, 4381, 4382,
    4383, 4384, 4385, 4386, 4387, 4388, 4389, 4390, 4391, 4392, 4393, 4394,
    4395, 4396, 4397, 4398, 4399, 4400, 4401, 4402, 4403, 4404, 4405, 4406,
    4407, 4408, 4409, 4410, 4411, 4412, 4413, 4414, 4415, 4416, 4417, 4418,
    4419, 4420, 4421, 4422, 4423, 4424, 4425, 4426, 4427, 4428, 4429, 4430,
    4431, 4432, 4433, 4434, 4435, 4436, 4437, 4438, 4439, 4440, 4441, 4442,
    4443, 4444, 4445, 4446, 4447, 4448, 4449, 4450, 4451, 4452, 4453, 4454,
    4455, 4456, 4457, 4458, 4459, 4460, 4461, 4462, 4463, 4464, 4465, 4466,
    4467, 4468, 4469, 4470, 4471, 4472, 4473, 4474, 4475, 4476, 4477, 4478,
    4479, 4480, 4481, 4482, 4483, 4484, 4485, 4486, 4487, 4488, 4489, 4490,
    4491, 4492, 4493, 4494, 4495, 4496, 4497, 4498, 4499, 4500, 4501, 4502,
    4503, 4504, 4505, 4506, 4507, 4508, 4509, 4510, 4511, 4512, 4513, 4514,
    4515, 4516, 4517, 4518, 4519, 4520, 4521, 4522, 4523, 4524, 4525, 4526,
    4527, 4528, 4529, 4530, 4531, 4532, 4533, 4534, 4535, 4536, 4537, 4538,
    4539, 4540, 4541, 4542, 4543, 4544, 4545, 4546, 4547, 4548, 4549, 4550,
    4551, 4552, 4553, 4554, 4555, 4556, 4557, 4558, 4559, 4560, 4561, 4562,
    4563, 4564, 4565, 4566, 4567, 4568, 4569, 4570, 4571, 4572, 4573, 4574,
    4575, 4576, 4577, 4578, 4579, 4580, 4581, 4582, 4583, 4584, 4585, 4586,
    4587, 4588, 4589, 4590, 4591, 4592, 4593, 4594, 4595, 4596, 4597, 4598,
    4599, 4600, 4601, 4602, 4603, 4604, 4605, 4606, 4607, 4608, 4609, 4610,
    4611, 4612, 4613, 4614, 4615, 4616, 4617, 4618, 4619, 4620, 4621, 4622,
    4623, 4624, 4625, 4626, 4627, 4628, 4629, 4630, 4631, 4632, 4633, 4634,
    4635, 4636, 4637, 4638, 4639, 4640, 4641, 4642, 4643, 4644, 4645, 4646,
    4647, 4648, 4649, 4650, 4651, 4652, 4653, 4654, 4655, 4656, 4657, 4658,
    4659, 4660, 4661, 4662, 4663, 4664, 4665, 4666, 4667, 4668, 4669, 4670,
    4671, 4672, 4673, 4674, 4675, 4676, 4677, 4678, 4679, 4680, 4681, 4682,
    4683, 4684, 4685, 4686, 4687, 4688, 4689, 4690, 4691, 4692, 4693, 4694,
    4695, 4696, 4697, 4698, 4699, 4700, 4701, 4702, 4703, 4704, 4705, 4706,
    4707, 4708, 4709, 4710, 4711, 4712, 4713, 4714, 4715, 4716, 4717, 4718,
    4719, 4720, 4721, 4722, 4723, 4724, 4725, 4726, 4727, 4728, 4729, 4730,
    4731, 4732, 4733, 4734, 4735, 4736, 4737, 4738, 4739, 4740, 4741, 4742,
    4743, 4744, 4745, 4746, 4747, 4748, 4749, 4750, 4751, 4752, 4753, 4754,
    4755, 4756, 4757, 4758, 4759, 4760, 4761, 4762, 4763, 4764, 4765, 4766,
    4767, 4768, 4769, 4770, 4771, 4772, 4773, 4774, 4775, 4776, 4777, 4778,
    4779, 4780, 4781, 4782, 4783, 4784, 4785, 4786, 4787, 4788, 4789, 4790,
    4791, 4792, 4793, 4794, 4795, 4796, 4797, 4798, 4799, 4800, 4801, 4802,
    4803, 4804, 4805, 4806, 4807, 4808, 4809, 4810, 4811, 4812, 4813, 4814,
    4815, 4816, 4817, 4818, 4819, 4820, 4821, 4822, 4823, 4824, 4825, 4826,
    4827, 4828, 4829, 4830, 4831, 4832, 4833, 4834, 4835, 4836, 4837, 4838,
    4839, 4840, 4841, 4842, 4843, 4844, 4845, 4846, 4847, 4848, 4849, 4850,
    4851, 4852, 4853, 4854, 4855, 4856, 4857, 4858, 4859, 4860, 4861, 4862,
    4863, 4864, 4865, 4866, 4867, 4868, 4869, 4870, 4871, 4872, 4873, 4874,
    4875, 4876, 4877, 4878, 4879, 4880, 4881, 4882, 4883, 4884, 4885, 4886,
    4887, 4888, 4889, 4890, 4891, 4892, 4893, 4894, 4895, 4896, 4897, 4898,
    4899, 4900, 4901, 4902, 4903, 4904, 4905, 4906, 4907, 4908, 4909, 4910,
    4911, 4912, 4913, 4914, 4915, 4916, 4917, 4918, 4919, 4920, 4921, 4922,
    4923, 4924, 4925, 4926, 4927, 4928, 4929, 4930, 4931, 4932, 4933, 4934,
    4935, 4936, 4937, 4938, 4939, 4940, 4941, 4942, 4943, 4944, 4945, 4946,
    4947, 4948, 4949, 4950, 4951, 4952, 4953, 4954, 4955, 4956, 4957, 4958,
    4959, 4960, 4961, 4962, 4963, 4964, 4965, 4966, 4967, 4968, 4969, 4970,
    4971, 4972, 4973, 4974, 4975, 4976, 4977, 4978, 4979, 4980, 4981, 4982,
    4983, 4984, 4985, 4986, 4987, 4988, 4989, 4990, 4991, 4992, 4993, 4994,
    4995, 4996, 4997, 4998, 4999, 5000, 5001, 5002, 5003, 5004, 5005, 5006,
    5007, 5008, 5009, 5010, 5011, 5012, 5013, 5014, 5015, 5016, 5017, 5018,
    5019, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030,
    5031, 5032, 5033, 5034, 5035, 5036, 5037, 5038, 5039, 5040, 5041, 5042,
    5043, 5044, 5045, 5046, 5047, 5048, 5049, 5050, 5051, 5052, 5053, 5054,
    5055, 5056, 5057, 5058, 5059, 5060, 5061, 5062, 5063, 5064, 5065, 5066,
    5067, 5068, 5069, 5070, 5071, 5072, 5073, 5074, 5075, 5076, 5077, 5078,
    5079, 5080, 5081, 5082, 5083, 5084, 5085, 5086, 5087, 5088, 5089, 5090,
    5091, 5092, 5093, 5094, 5095, 5096, 5097, 5098, 5099, 5100, 5101, 5102,
    5103, 5104, 5105, 5106, 5107, 5108, 5109, 5110, 5111, 5112, 5113, 5114,
    5115, 5116, 5117, 5118, 5119, 5120, 5121, 5122, 5123, 5124, 5125, 5126,
    5127, 5128, 5129, 5130, 5131, 5132, 5133, 5134, 5135, 5136, 5137, 5138,
    5139, 5140, 5141, 5142, 5143, 5144, 5145, 5146, 5147, 5148, 5149, 5150,
    5151, 5152, 5153, 5154, 5155, 5156, 5157, 5158, 5159, 5160, 5161, 5162,
    5163, 5164, 5165, 5166, 5167, 5168, 5169, 5170, 5171, 5172, 5173, 5174,
    5175, 5176, 5177, 5178, 5179, 5180, 5181, 5182, 5183, 5184, 5185, 5186,
    5187, 5188, 5189, 5190, 5191, 5192, 5193, 5194, 5195, 5196, 5197, 5198,
    5199, 5200, 5201, 5202, 5203, 5204, 5205, 5206, 5207, 5208, 5209, 5210,
    5211, 5212, 5213, 5214, 5215, 5216, 5217, 5218, 5219, 5220, 5221, 5222,
    5223, 5224, 5225, 5226, 5227, 5228, 5229, 5230, 5231, 5232, 5233, 5234,
    5235, 5236, 5237, 5238, 5239, 5240, 5241, 5242, 5243, 5244, 5245, 5246,
    5247, 5248, 5249, 5250, 5251, 5252, 5253, 5254, 5255, 5256, 5257, 5258,
    5259, 5260, 5261, 5262, 5263, 5264, 5265, 5266, 5267, 5268, 5269, 5270,
    5271, 5272, 5273, 5274, 5275, 5276, 5277, 5278, 5279, 5280, 5281, 5282,
    5283, 5284, 5285, 5286, 5287, 5288, 5289, 5290, 5291, 5292, 5293, 5294,
    5295, 5296, 5297, 5298, 5299, 5300, 5301, 5302, 5303, 5304, 5305, 5306,
    5307, 5308, 5309, 5310, 5311, 5312, 5313, 5314, 5315, 5316, 5317, 5318,
    5319, 5320, 5321, 5322, 5323, 5324, 5325, 5326, 5327, 5328, 5329, 5330,
    5331, 5332, 5333, 5334, 5335, 5336, 5337, 5338, 5339, 5340, 5341, 5342,
    5343, 5344, 5345, 5346, 5347, 5348, 5349, 5350, 5351, 5352, 5353, 5354,
    5355, 5356, 5357, 5358, 5359, 5360, 5361, 5362, 5363, 5364, 5365, 5366,
    5367, 5368, 5369, 5370, 5371, 5372, 5373, 5374, 5375, 5376, 5377, 5378,
    5379, 5380, 5381, 5382, 5383, 5384, 5385, 5386, 5387, 5388, 5389, 5390,
    5391, 5392, 5393, 5394, 5395, 5396, 5397, 5398, 5399, 5400, 5401, 5402,
    5403, 5404, 5405, 5406, 5407, 5408, 5409, 5410, 5411, 5412, 5413, 5414,
    5415, 5416, 5417, 5418, 5419, 5420, 5421, 5422, 5423, 5424, 5425, 5426,
    5427, 5428, 5429, 5430, 5431, 5432, 5433, 5434, 5435, 5436, 5437, 5438,
    5439, 5440, 5441, 5442, 5443, 5444, 5445, 5446, 5447, 5448, 5449, 5450,
    5451, 5452, 5453, 5454, 5455, 5456, 5457, 5458, 5459, 5460, 5461, 5462,
    5463, 5464, 5465, 5466, 5467, 5468, 5469, 5470, 5471, 5472, 5473, 5474,
    5475, 5476, 5477, 5478, 5479, 5480, 5481, 5482, 5483, 5484, 5485, 5486,
    5487, 5488, 5489, 5490, 5491, 5492, 5493, 5494, 5495, 5496, 5497, 5498,
    5499, 5500, 5501, 5502, 5503, 5504, 5505, 5506, 5507, 5508, 5509, 5510,
    5511, 5512, 5513, 5514, 5515, 5516, 5517, 5518, 5519, 5520, 5521, 5522,
    5523, 5524, 5525, 5526, 5527, 5528, 5529, 5530, 5531, 5532, 5533, 5534,
    5535, 5536, 5537, 5538, 5539, 5540, 5541, 5542, 5543, 5544, 5545, 5546,
    5547, 5548, 5549, 5550, 5551, 5552, 5553, 5554, 5555, 5556, 5557, 5558,
    5559, 5560, 5561, 5562, 5563, 5564, 5565, 5566, 5567, 5568, 5569, 5570,
    5571, 5572, 5573, 5574, 5575, 5576, 5577, 5578, 5579, 5580, 5581, 5582,
    5583, 5584, 5585, 5586, 5587, 5588, 5589, 5590, 5591, 5592, 5593, 5594,
    5595, 5596, 5597, 5598, 5599, 5600, 5601, 5602, 5603, 5604, 5605, 5606,
    5607, 5608, 5609, 5610, 5611, 5612, 5613, 5614, 5615, 5616, 5617, 5618,
    5619, 5620, 5621, 5622, 5623, 5624, 5625, 5626, 5627, 5628, 5629, 5630,
    5631, 5632, 5633, 5634, 5635, 5636, 5637, 5638, 5639, 5640, 5641, 5642,
    5643, 5644, 5645, 5646, 5647, 5648, 5649, 5650, 5651, 5652, 5653, 5654,
    5655, 5656, 5657, 5658, 5659, 5660, 5661, 5662, 5663, 5664, 5665, 5666,
    5667, 5668, 5669, 5670, 5671, 5672, 5673, 5674, 5675, 5676, 5677, 5678,
    5679, 5680, 5681, 5682, 5683, 5684, 5685, 5686, 5687, 5688, 5689, 5690,
    5691, 5692, 5693, 5694, 5695, 5696, 5697, 5698, 5699, 5700, 5701, 5702,
    5703, 5704, 5705, 5706, 5707,
  ],

  plethPubnubData: [
    1142, 1120, 1096, 1074, 1055, 1042, 1034, 1032, 1036, 1047, 1065, 1090,
    1119, 1154, 1195, 1242, 1295, 1353, 1417, 1486, 1559, 1636, 1717, 1800,
    1885, 1961, 2032, 2111, 2193, 2277, 2360, 2440, 2518, 2593, 2664, 2731,
    2793, 2840, 2878, 2918, 2956, 2989, 3016, 3037, 3052, 3060, 3062, 3058,
    3048, 3032, 3010, 2984, 2954, 2920, 2883, 2842, 2798, 2752, 2704, 2654,
    2604, 2553, 2507, 2464, 2418, 2372, 2327, 2284, 2243, 2205, 2171, 2140,
    2112, 2088, 2068, 2053, 2043, 2034, 2028, 2025, 2025, 2028, 2033, 2041,
    2051, 2062, 2075, 2087, 2098, 2111, 2124, 2137, 2149, 2159, 2167, 2172,
  ],
  // [1245, 1203, 1164, 1130, 1100, 1075, 1055, 1041, 1037, 1040, 1045, 1055, 1071, 1092, 1120, 1155, 1196, 1243, 1296, 1354, 1411, 1470, 1537, 1610, 1687, 1768, 1852, 1937, 2022, 2108, 2194, 2279, 2363, 2433, 2495, 2562, 2630, 2697, 2760, 2818, 2870, 2917, 2958, 2992, 3020, 3036, 3044, 3051, 3053, 3051, 3043, 3029, 3010, 2986, 2957, 2924, 2887, 2850, 2811, 2768, 2722, 2674, 2624, 2574],

  respPubnubData: [
    1181, 1227, 1277, 1330, 1386, 1445, 1507, 1572, 1640, 1708, 1777, 1844,
    1910, 1974, 2037, 2098, 2157, 2212, 2263, 2310, 2351, 2385, 2413, 2436,
    2453, 2463, 2467, 2466, 2459, 2445, 2425, 2397, 2363, 2323, 2278, 2227,
    2172, 2114, 2053, 1988, 1921, 1850, 1776, 1702, 1628, 1555, 1483, 1413,
  ],

  // [949, 951, 953, 955, 957, 959, 961, 963, 965, 967, 969, 971, 973, 975, 977, 979, 981, 983, 985, 987, 988, 989, 990, 991, 993, 995, 999, 1006, 1015, 1029, 1048, 1073],

  ecgPubnubValues: [
    8209, 8184, 8168, 8164, 8166, 8168, 8168, 8168, 8169, 8169, 8169, 8169,
    8170, 8170, 8170, 8170, 8171, 8171, 8171, 8171, 8172, 8172, 8172, 8172,
    8173, 8173, 8173, 8173, 8174, 8174, 8174, 8174, 8175, 8175, 8175, 8175,
    8175, 8176, 8176, 8176, 8176, 8176, 8176, 8176, 8176, 8177, 8178, 8179,
    8179, 8179, 8179, 8179, 8179, 8180, 8180, 8180, 8180, 8180, 8180, 8180,
    8180, 8181, 8181, 8181, 8181, 8181, 8181, 8181, 8181, 8182, 8182, 8182,
    8182, 8182, 8182, 8182, 8182, 8183, 8183, 8183, 8183, 8183, 8183, 8184,
    8184, 8184, 8184, 8184, 8184, 8184, 8184, 8184, 8184, 8184, 8185, 8185,
    8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185,
    8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185,
    8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185, 8185,
    8185, 8185, 8185, 8185, 8188, 8200, 8222, 8250, 8278, 8303, 8328, 8352,
    8365, 8356, 8327, 8293, 8264, 8236, 8209, 8184, 8168, 8164, 8166, 8168,
    8168, 8168, 8169, 8169, 8169, 8169, 8170, 8170, 8170, 8170, 8171, 8171,
    8171, 8171, 8172, 8172, 8172, 8172, 8173, 8173, 8173, 8173, 8174, 8174,
    8174, 8174, 8175, 8175, 8175, 8175, 8175, 8176, 8176, 8176, 8176, 8176,
  ],
  ecgPubnubValues1: [
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
    8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150, 8150,
  ],

  ecgHeartRateValues: [
    1181, 1227, 1277, 1330, 1386, 1445, 1507, 1572, 1640, 1708, 1777, 1844,
    1910, 1974, 2037, 2098, 2157, 2212, 2263, 2310, 2351, 2385, 2413, 2436,
    2453, 2463, 2467, 2466, 2459, 2445, 2425, 2397, 2363, 2323, 2278, 2227,
    2172, 2114, 2053, 1988, 1921, 1850, 1776, 1702, 1628, 1555, 1483, 1413,
  ],

  bloodPressureValues: [
    1181, 1227, 1277, 1330, 1386, 1445, 1507, 1572, 1640, 1708, 1777, 1844,
    1910, 1974, 2037, 2098, 2157, 2212, 2263, 2310, 2351, 2385, 2413, 2436,
    2453, 2463, 2467, 2466, 2459, 2445, 2425, 2397, 2363, 2323, 2278, 2227,
    2172, 2114, 2053, 1988, 1921, 1850, 1776, 1702, 1628, 1555, 1483, 1413,
  ],
  bloodVolumeValues: [
    1142, 1120, 1096, 1074, 1055, 1042, 1034, 1032, 1036, 1047, 1065, 1090,
    1119, 1154, 1195, 1242, 1295, 1353, 1417, 1486, 1559, 1636, 1717, 1800,
    1885, 1961, 2032, 2111, 2193, 2277, 2360, 2440, 2518, 2593, 2664, 2731,
    2793, 2840, 2878, 2918, 2956, 2989, 3016, 3037, 3052, 3060, 3062, 3058,
    3048, 3032, 3010, 2984, 2954, 2920, 2883, 2842, 2798, 2752, 2704, 2654,
    2604, 2553, 2507, 2464, 2418, 2372, 2327, 2284, 2243, 2205, 2171, 2140,
    2112, 2088, 2068, 2053, 2043, 2034, 2028, 2025, 2025, 2028, 2033, 2041,
    2051, 2062, 2075, 2087, 2098, 2111, 2124, 2137, 2149, 2159, 2167, 2172,
  ],
  bloodOxygenationValues: [
    0.100243, 0.100496, 0.100839, 0.100872, 0.100878, 0.101261, 0.101264,
    0.10163, 0.101631, 0.101646, 0.101765, 0.101982, 0.102023, 0.102052,
    0.102589, 0.102626, 0.103124, 0.103174, 0.103526, 0.103557, 0.103668,
    0.103905, 0.10394, 0.104268, 0.104282, 0.104283, 0.104659, 0.104661,
    0.104703, 0.105037, 0.105085, 0.10519, 0.105411, 0.105415, 0.105468,
    0.105792, 0.105793, 0.105851, 0.106169, 0.106171, 0.106233, 0.106332,
    0.106548, 0.106554, 0.106926, 0.106997, 0.107303, 0.107304, 0.10738,
    0.107682, 0.107698, 0.107762, 0.107855, 0.108061, 0.108145, 0.108439,
    0.10846, 0.108528, 0.10891, 0.108913, 0.109198, 0.109295, 0.109377,
    0.109575, 0.109576, 0.109603, 0.109678, 0.109954, 0.110061, 0.110713,
    0.110746, 0.110824, 0.1109, 0.111207, 0.111854, 0.111889, 0.11197, 0.112042,
    0.112353, 0.112652, 0.112708, 0.112963, 0.113136, 0.113218, 0.113261,
    0.113414, 0.113504, 0.113506, 0.114139, 0.114328, 0.114558, 0.114618,
    0.114787, 0.115295, 0.11532, 0.115421, 0.115635, 0.116083, 0.116196,
    0.11634, 0.116436, 0.117227, 0.117377, 0.117567, 0.117694, 0.117989,
    0.118089, 0.118723, 0.118752, 0.118794, 0.119018, 0.119365, 0.119493,
    0.119515, 0.119615, 0.119741, 0.120076, 0.120249, 0.120278, 0.120632,
    0.120837, 0.121012, 0.121041, 0.121344, 0.121395, 0.121775, 0.121803,
    0.12203, 0.12207, 0.122164, 0.12241, 0.122567, 0.122668, 0.122865, 0.123047,
    0.123301, 0.123329, 0.123351, 0.12338, 0.12357, 0.123712, 0.123752,
    0.123813, 0.124259, 0.124445, 0.124474, 0.124551, 0.124693, 0.124883,
    0.125124, 0.125215, 0.125238, 0.125255, 0.125544, 0.12562, 0.125632,
    0.125704, 0.125727, 0.125755, 0.125822, 0.126003, 0.126016, 0.126017,
    0.126055, 0.126115, 0.126142, 0.126269, 0.126369, 0.126385, 0.126393,
    0.126425, 0.126488, 0.126565, 0.126583, 0.126616, 0.126678, 0.126768,
    0.126808, 0.126869, 0.126947, 0.126964, 0.127096, 0.127151, 0.127156,
    0.127283, 0.127333, 0.12739, 0.12741, 0.127533, 0.127535, 0.127535,
    0.127617, 0.12763, 0.12767, 0.127725, 0.127809, 0.127809, 0.12782, 0.127862,
    0.127905, 0.127915, 0.127916, 0.127916, 0.127917, 0.127917, 0.127917,
    0.127918, 0.127918, 0.127918, 0.127918, 0.127919, 0.127919, 0.12792,
    0.12792, 0.12792, 0.12792, 0.12792, 0.12792, 0.128037, 0.128106, 0.12817,
    0.128292, 0.128297, 0.128302, 0.128303, 0.128303, 0.128303, 0.128304,
    0.128304, 0.128305, 0.128305, 0.128305, 0.128305, 0.128306, 0.128306,
    0.128306, 0.128307, 0.128307, 0.128307, 0.128307, 0.128308, 0.128308,
    0.128309, 0.128309, 0.128309, 0.128309, 0.128263, 0.128129, 0.128073,
    0.128035, 0.127978, 0.127941, 0.127936, 0.127931, 0.127932, 0.127932,
    0.127932, 0.127933, 0.127933, 0.127933, 0.127933, 0.127934, 0.127934,
    0.127934, 0.127934, 0.127935, 0.127935, 0.127935, 0.127935, 0.127936,
    0.127869, 0.127701, 0.127693, 0.127616, 0.127557, 0.127557, 0.127557,
    0.127558, 0.127558, 0.127558, 0.127558, 0.127558, 0.127559, 0.127559,
    0.127508, 0.12748, 0.127474, 0.127318, 0.127291, 0.127285, 0.127181,
    0.127181, 0.127181, 0.127182, 0.127182, 0.127182, 0.127183, 0.127183,
    0.127183, 0.1271, 0.127011, 0.126941, 0.126816, 0.126805, 0.126805,
    0.126805, 0.126806, 0.126807, 0.126807, 0.126807, 0.126706, 0.126608,
    0.126428, 0.126429, 0.126429, 0.126429, 0.12643, 0.12643, 0.12643, 0.126374,
    0.126355, 0.126348, 0.126348, 0.126254, 0.126185, 0.126161, 0.12616,
    0.126071, 0.126067, 0.126052, 0.125976, 0.125946, 0.125946, 0.12582,
    0.125722, 0.125699, 0.125695, 0.125673, 0.125674, 0.125674, 0.125675,
    0.125675, 0.125675, 0.125676, 0.125676, 0.125676, 0.125616, 0.125504,
    0.125412, 0.125409, 0.125408, 0.125332, 0.125313, 0.125313, 0.125312,
    0.125312, 0.125297, 0.125217, 0.125216, 0.125216, 0.125128, 0.125121,
    0.12512, 0.12512, 0.125048, 0.125025, 0.125024, 0.125023, 0.125023,
    0.125023, 0.124928, 0.124927, 0.124927, 0.124926, 0.124919, 0.12492,
    0.12492, 0.12492, 0.12492, 0.12492, 0.12492, 0.12492, 0.12492, 0.12492,
    0.12492, 0.124921, 0.124921, 0.124921, 0.124921, 0.124921, 0.124859,
    0.124847, 0.124827, 0.124827, 0.124826, 0.124826, 0.124825, 0.124824,
    0.124824, 0.124824, 0.124729, 0.124728, 0.124728, 0.124727, 0.124727,
    0.124726, 0.12467, 0.124658, 0.124631, 0.124631, 0.12463, 0.12463, 0.124629,
    0.124629, 0.124628, 0.124543, 0.124533, 0.124533, 0.124532, 0.124532,
    0.124531, 0.124531, 0.12453, 0.12453, 0.124469, 0.124435, 0.124434,
    0.124434, 0.124433, 0.124433, 0.124432, 0.124432, 0.124431, 0.124431,
    0.124386, 0.124336, 0.124335, 0.124335, 0.124334, 0.124334, 0.124334,
    0.124238, 0.124238, 0.124237, 0.124186, 0.124164, 0.124135, 0.124134,
    0.124134, 0.12408, 0.124007, 0.124006, 0.124005, 0.123941, 0.123878,
    0.123878, 0.123877, 0.123827, 0.123786, 0.12376, 0.123721, 0.123714,
    0.123667, 0.123667, 0.123666, 0.123619, 0.123574, 0.123573, 0.123573,
    0.123532, 0.123481, 0.123481, 0.12348, 0.12348, 0.123431, 0.123407,
    0.123381, 0.123381, 0.12338, 0.12332, 0.123258, 0.123257, 0.123257,
    0.123257, 0.123256, 0.123187, 0.123135, 0.123135, 0.123134, 0.123133,
    0.123133, 0.123067, 0.123029, 0.123029, 0.123029, 0.123029, 0.123029,
    0.123029, 0.123029, 0.123029, 0.123029, 0.123029, 0.123029, 0.123029,
    0.123029, 0.12303, 0.12303, 0.12303, 0.12303, 0.12303, 0.12303, 0.12303,
    0.12303, 0.12303, 0.12303, 0.12303, 0.12303, 0.12303, 0.12303, 0.12303,
    0.123031, 0.123031, 0.12303, 0.12303, 0.12303, 0.123029, 0.123029, 0.123028,
    0.122961, 0.122938, 0.122938, 0.122937, 0.122937, 0.122936, 0.122869,
    0.122845, 0.122845, 0.122844, 0.122773, 0.122753, 0.122752, 0.122752,
    0.122752, 0.122751, 0.122679, 0.122661, 0.12266, 0.12266, 0.122659,
    0.122659, 0.122653, 0.122469, 0.122428, 0.122427, 0.122427, 0.122425,
    0.122424, 0.122422, 0.122215, 0.122181, 0.122179, 0.122178, 0.122176,
    0.121963, 0.121932, 0.121931, 0.121929, 0.121928, 0.121893, 0.121824,
    0.121816, 0.121815, 0.121815, 0.121814, 0.121814, 0.121731, 0.121722,
    0.121722, 0.121634, 0.121628, 0.121542, 0.121533, 0.121515, 0.121412,
    0.121295, 0.121285, 0.121173, 0.121136, 0.121058, 0.120971, 0.12088,
    0.120869, 0.120758, 0.120691, 0.120687, 0.120679, 0.120679, 0.120497,
    0.120489, 0.120408, 0.12038, 0.120273, 0.120166, 0.120157, 0.12004,
    0.120001, 0.119937, 0.119936, 0.11992, 0.119833, 0.119748, 0.119644,
    0.119636, 0.119623, 0.119412, 0.119271, 0.119261, 0.119244, 0.119181,
    0.119162, 0.118992, 0.11898, 0.118972, 0.118866, 0.118658, 0.118639,
    0.118629, 0.118487, 0.118426, 0.118317, 0.118309, 0.118237, 0.118109,
    0.117885, 0.117695, 0.117652, 0.117349, 0.117289, 0.117265, 0.117178,
    0.11717, 0.117006, 0.116981, 0.116971, 0.116766, 0.116742, 0.116605,
    0.116592, 0.116534, 0.116345, 0.116326, 0.116317, 0.116214, 0.116137,
    0.116099, 0.116011, 0.115858, 0.115835, 0.115661, 0.115531, 0.11547,
    0.11528, 0.115152, 0.11511, 0.115076, 0.114959, 0.114831, 0.114748, 0.11472,
    0.114698, 0.114608, 0.114547, 0.114418, 0.114335, 0.114324, 0.114319,
    0.114245, 0.114198, 0.113945, 0.113941, 0.113828, 0.113782, 0.113567,
    0.113376, 0.113257, 0.113182, 0.113076, 0.113057, 0.112931, 0.11293,
    0.112853, 0.112803, 0.112644, 0.112614, 0.112497, 0.112233, 0.112116,
    0.112044, 0.112045, 0.112045, 0.112045, 0.112045, 0.112046, 0.112042,
    0.112042, 0.111926, 0.111885, 0.111661, 0.111546, 0.111287, 0.111283,
    0.111179, 0.111079, 0.111029, 0.110953, 0.110908, 0.110899, 0.110746,
    0.110709, 0.110594, 0.110593, 0.110592, 0.11059, 0.110518, 0.110399,
    0.110398, 0.110397, 0.110327, 0.110206, 0.11015, 0.11006, 0.110041,
    0.110015, 0.109933, 0.109932, 0.109887, 0.109805, 0.109805, 0.109771,
    0.109584, 0.109497, 0.109298, 0.109243, 0.109075, 0.109074, 0.109011,
    0.109003, 0.10894, 0.108939, 0.108844, 0.108813, 0.108749, 0.10874,
    0.108718, 0.108654, 0.108633, 0.108533, 0.108533, 0.108493, 0.108405,
    0.108405, 0.108396, 0.108278, 0.108277, 0.108254, 0.108233, 0.108097,
    0.108096, 0.107905, 0.107904, 0.107852, 0.107713, 0.107712, 0.107709,
    0.107662, 0.107496, 0.107386, 0.107385, 0.107384, 0.107257, 0.107257,
    0.107227, 0.107132, 0.10713, 0.107129, 0.107117, 0.107002, 0.106875,
    0.106848, 0.106748, 0.106738, 0.106715, 0.106571, 0.106561, 0.10637,
    0.106336, 0.10618, 0.106147, 0.105989, 0.105979, 0.105965, 0.105867,
    0.105858, 0.105839, 0.105713, 0.1056, 0.105414, 0.105392, 0.105242,
    0.105223, 0.105204, 0.105203, 0.105032, 0.105014, 0.104842, 0.104841,
    0.10484, 0.104831, 0.104713, 0.104713, 0.104706, 0.104705, 0.104603,
    0.104585, 0.104585, 0.104584, 0.10458, 0.104463, 0.104458, 0.104458,
    0.104457, 0.104457, 0.104363, 0.104363, 0.104363, 0.104362, 0.104269,
    0.104267, 0.104266, 0.10419, 0.104176, 0.104175, 0.104171, 0.104085,
    0.104076, 0.103824, 0.103808, 0.103572, 0.103554, 0.103352, 0.103325,
    0.103324, 0.103315, 0.10323, 0.103229, 0.10322, 0.103135, 0.103125,
    0.103041, 0.10303, 0.103029, 0.102957, 0.102947, 0.102947, 0.10293,
    0.102822, 0.102677, 0.102676, 0.102675, 0.10257, 0.102568, 0.102528,
    0.102526, 0.102525, 0.102523, 0.102341, 0.102319, 0.102268, 0.102068,
    0.102013, 0.101809, 0.101724, 0.101717, 0.101695, 0.1016, 0.101441,
    0.101433, 0.101431, 0.101377, 0.100869, 0.100699, 0.100671, 0.100548,
    0.100517, 0.100307, 0.100295, 0.100292, 0.100059, 0.0999171, 0.0997449,
    0.0996785, 0.0995337, 0.0992957, 0.0992835, 0.0991549, 0.0991092, 0.0987978,
    0.0985889, 0.0983961, 0.0983654, 0.0982848, 0.0980191, 0.0980173, 0.0979717,
    0.0976605, 0.0974027, 0.0972606, 0.0972586, 0.0970209, 0.0970072, 0.0969759,
    0.0968797, 0.0967546, 0.0965157, 0.0965009, 0.0964562, 0.0963128, 0.0959543,
    0.0958872, 0.0957422, 0.0956168, 0.0955864, 0.0955047, 0.0954609, 0.0953634,
    0.0950537, 0.0948879, 0.0948589, 0.094604, 0.0945832, 0.0944266, 0.0944157,
    0.0943945, 0.0942379, 0.0942265, 0.0942258, 0.0941991, 0.0940735, 0.0939743,
    0.0939479, 0.0938633, 0.093847, 0.0936199, 0.093493, 0.0934312, 0.0932795,
    0.0931142, 0.0930882, 0.0930646, 0.092939, 0.0928367, 0.0928132, 0.0927269,
    0.0927094, 0.0926768, 0.0923569, 0.0922982, 0.0921416, 0.0921096, 0.0919506,
    0.0919109, 0.0917343, 0.0916587, 0.0912283, 0.0911925, 0.0911913, 0.0911732,
    0.0911725, 0.0910475, 0.0909392, 0.0908313, 0.0908125, 0.0907871, 0.090623,
    0.0905984, 0.0904097, 0.0902722, 0.0902207, 0.0900537, 0.0900532, 0.0900393,
    0.0899138, 0.0898207, 0.0897999, 0.0897882, 0.0896749, 0.0895953, 0.0895787,
    0.089571, 0.0894775, 0.089477, 0.0893891, 0.0893835, 0.0893118, 0.0892967,
    0.0892901, 0.0890364, 0.0890236, 0.0887718, 0.0885778, 0.0885374, 0.0885281,
    0.08852, 0.0880723, 0.0880198, 0.0880144, 0.087778, 0.0877733, 0.0877723,
    0.0876782, 0.0876045, 0.0875842, 0.0875835, 0.0874886, 0.0874883, 0.0873998,
    0.0873886, 0.0871892, 0.0871364, 0.0871291, 0.0866404, 0.0866358, 0.0866306,
    0.0865357, 0.0865101, 0.0863845, 0.0863774, 0.0862616, 0.0861833, 0.0861649,
    0.0860641, 0.0859763, 0.0859694, 0.0859, 0.0858834, 0.0856266, 0.0856065,
    0.0851684, 0.0851241, 0.0851231, 0.0850207, 0.0849343, 0.0848571, 0.0848403,
    0.0847458, 0.0844932, 0.0844693, 0.0840308, 0.0839876, 0.0839865, 0.0838488,
    0.0837352, 0.0836296, 0.0836077, 0.0835995, 0.0835145, 0.0835052, 0.0834204,
    0.0834109, 0.0832456, 0.0832317, 0.0832294, 0.0832098, 0.0829834, 0.0827046,
    0.0824782, 0.0824701, 0.0823118, 0.082267, 0.0820974, 0.0818888, 0.0818885,
    0.0817189, 0.0817113, 0.0817035, 0.0816054, 0.0814644, 0.0814516, 0.0813325,
    0.0812123, 0.0811995, 0.0810999, 0.0809603, 0.0809537, 0.0809445, 0.0806032,
    0.0805657, 0.0803949, 0.0803764, 0.080195, 0.0801896, 0.0800759, 0.0799611,
    0.0799377, 0.0798161, 0.079346, 0.0793237, 0.0793005, 0.0790568, 0.0788806,
    0.0788645, 0.0787784, 0.0786786, 0.0785482, 0.0784426, 0.0784222, 0.0783065,
    0.0782998, 0.0781044, 0.0779441, 0.0777259, 0.0775505, 0.077541, 0.0775385,
    0.0774299, 0.0774127, 0.0772872, 0.0771766, 0.0771622, 0.0771613, 0.0771607,
    0.0769816, 0.0769738, 0.0769726, 0.0767851, 0.0766138, 0.076597, 0.0765964,
    0.0764078, 0.0764034, 0.0762831, 0.0762806, 0.0760377, 0.0760271, 0.0760263,
    0.0760246, 0.075844, 0.0758362, 0.0758356, 0.0758351, 0.0758339, 0.0758328,
    0.0758316, 0.0756406, 0.07564, 0.0756395, 0.0756383, 0.0756371, 0.0754739,
    0.0754462, 0.075445, 0.0754444, 0.0754438, 0.0754427, 0.0752754, 0.0752659,
    0.0752564, 0.0752557, 0.0752549, 0.0752545, 0.0751278, 0.075127, 0.0751262,
    0.0751255, 0.0751247, 0.0750249, 0.0750201, 0.074998, 0.0749976, 0.0749968,
    0.0749961, 0.0749953, 0.074887, 0.0748682, 0.0748678, 0.0748674, 0.0748667,
    0.0748659, 0.0747388, 0.074738, 0.0747378, 0.0747374, 0.0747367, 0.0746453,
    0.0746417, 0.0746096, 0.0746088, 0.074608, 0.0746076, 0.0746073, 0.0745082,
    0.074466, 0.0744649, 0.0744637, 0.0744626, 0.074462, 0.0744614, 0.0743299,
    0.0742704, 0.0742693, 0.0742681, 0.074267, 0.0742664, 0.0742658, 0.0742646,
    0.0741442, 0.0740737, 0.0740725, 0.0740713, 0.0740708, 0.0740702, 0.074069,
    0.0739511, 0.073878, 0.0738769, 0.0738757, 0.0738752, 0.0738746, 0.0738734,
    0.0738723, 0.0737495, 0.0736585, 0.0736569, 0.0736561, 0.0736554, 0.0736538,
    0.0736523, 0.0735135, 0.0733974, 0.0733958, 0.0733951, 0.0733943, 0.073262,
    0.0731394, 0.0730079, 0.0729901, 0.072939, 0.0728734, 0.0728138, 0.0728134,
    0.0727463, 0.0726894, 0.0726886, 0.0726879, 0.0726213, 0.0726113, 0.0725786,
    0.0725781, 0.0725775, 0.0725769, 0.0725766, 0.0725763, 0.0725758, 0.0724869,
    0.0724863, 0.0724858, 0.0724852, 0.0724849, 0.0724846, 0.0724302, 0.0724283,
    0.0723946, 0.0723941, 0.0723935, 0.0723932, 0.0723929, 0.072336, 0.0722331,
    0.0721606, 0.0720036, 0.0719115, 0.0719099, 0.0719091, 0.0719084, 0.0717418,
    0.0716658, 0.0716643, 0.0716635, 0.0716627, 0.0716612, 0.0716596, 0.071498,
    0.0714737, 0.0714537, 0.0714532, 0.0714529, 0.0714526, 0.071452, 0.0714514,
    0.071362, 0.0713614, 0.0713612, 0.0713609, 0.0713603, 0.0713597, 0.0712942,
    0.0712897, 0.0712697, 0.0712694, 0.0712691, 0.0712686, 0.071268, 0.071178,
    0.0711777, 0.0711774, 0.0711769, 0.0711763, 0.0711757, 0.0711056, 0.0710955,
    0.0710847, 0.071084, 0.0710832, 0.0710828, 0.0710824, 0.0710817, 0.0710809,
    0.0710801, 0.0709622, 0.0709615, 0.0709607, 0.0709603, 0.0709599, 0.0709592,
    0.0708569, 0.0708492, 0.0708398, 0.070839, 0.0708382, 0.0708378, 0.0708375,
    0.0708367, 0.0707173, 0.0707167, 0.0707164, 0.0707152, 0.0707147, 0.0707141,
    0.0707129, 0.0705485, 0.0705306, 0.07053, 0.0705289, 0.0703411, 0.07017,
    0.0701564, 0.0699605, 0.0699579, 0.069847, 0.0697062, 0.0695949, 0.0695847,
    0.0695791, 0.069453, 0.069343, 0.0692003, 0.0691992, 0.0690359, 0.0690186,
    0.0688192, 0.0686288, 0.0684673, 0.0684496, 0.0684415, 0.0682068, 0.0681866,
    0.0680627, 0.0679722, 0.067966, 0.0678871, 0.0677931, 0.0676845, 0.0674697,
    0.0674423, 0.0674242, 0.0669641, 0.0669252, 0.0668346, 0.0668274, 0.0667511,
    0.0666381, 0.0665623, 0.0665469, 0.0665419, 0.0664258, 0.0661681, 0.0661597,
    0.0660124, 0.0657954, 0.0656336, 0.0655894, 0.0654093, 0.0652869, 0.0651801,
    0.0651498, 0.0650305, 0.0648765, 0.0646561, 0.0646393, 0.064498, 0.0644489,
    0.0642718, 0.0641702, 0.0641368, 0.0640447, 0.064022, 0.063893, 0.0638835,
    0.0636428, 0.0635397, 0.0635141, 0.0635065, 0.0633169, 0.0632498, 0.0632321,
    0.0631359, 0.0628594, 0.0626053, 0.0624279, 0.0623797, 0.0623766, 0.0623672,
    0.0621774, 0.0620175, 0.061999, 0.0619983, 0.0619843, 0.0617309, 0.0616449,
    0.0616195, 0.0614308, 0.0614063, 0.0612159, 0.0610255, 0.060898, 0.060861,
    0.0608608, 0.0607171, 0.06059, 0.0604819, 0.0604055, 0.0603872, 0.0603731,
    0.0601835, 0.0601832, 0.0601213, 0.0601037, 0.0600833, 0.0599768, 0.0598741,
    0.0598299, 0.0597249, 0.0595018, 0.0593787, 0.0593435, 0.0593111, 0.0589661,
    0.0589662, 0.0589666, 0.0589667, 0.0589668, 0.0589672, 0.0589674, 0.0589677,
    0.0589677, 0.0589678, 0.05893, 0.0586156, 0.058585, 0.0585492, 0.0584249,
    0.0582339, 0.0582328, 0.0582091, 0.0581822, 0.0579714, 0.0579539, 0.0579287,
    0.0578443, 0.0578303, 0.0578091, 0.0578088, 0.0577456, 0.0576504, 0.0576192,
    0.0575446, 0.0574608, 0.0574602, 0.057452, 0.0574218, 0.0573359, 0.0572088,
    0.057208, 0.057197, 0.0571686, 0.0570732, 0.0568952, 0.0568357, 0.0568351,
    0.0567042, 0.0567031, 0.0566447, 0.0565121, 0.0565109, 0.0565005, 0.0563144,
    0.056281, 0.0561918, 0.056191, 0.0560639, 0.0560631, 0.0560597, 0.0560275,
    0.055936, 0.0559356, 0.0558148, 0.0557463, 0.0556511, 0.0556505, 0.0556504,
    0.0556251, 0.0555574, 0.0555547, 0.0555212, 0.0553945, 0.055303, 0.0553013,
    0.0551786, 0.0551719, 0.0551711, 0.0551222, 0.0549801, 0.0547891, 0.0547879,
    0.0547417, 0.0546062, 0.0545969, 0.0545958, 0.0544198, 0.0544199, 0.0544199,
    0.0544204, 0.0544206, 0.054421, 0.0544215, 0.0544216, 0.0544216, 0.0544217,
    0.0544221, 0.0544075, 0.0544067, 0.0543856, 0.0543852, 0.0542796, 0.0542788,
    0.0542598, 0.0541677, 0.0540433, 0.0540256, 0.0540248, 0.0540079, 0.0538977,
    0.0538823, 0.0537706, 0.0537698, 0.0537571, 0.0537568, 0.0536645, 0.0536616,
    0.0536145, 0.053442, 0.0534408, 0.0534258, 0.0532498, 0.0532487, 0.0532475,
    0.0532371, 0.0530926, 0.0530565, 0.0530493, 0.0530482, 0.0529057, 0.0529058,
    0.0529058, 0.0529058, 0.0529064, 0.052907, 0.052907, 0.0529074, 0.0529075,
    0.0529075, 0.052908, 0.0528818, 0.0528814, 0.0528811, 0.0528764, 0.0528757,
    0.0527563, 0.0527486, 0.0527478, 0.0526307, 0.0526207, 0.0526199, 0.0526192,
    0.0525292, 0.0525269, 0.052493, 0.0524734, 0.0524722, 0.0523047, 0.0523041,
    0.0521154, 0.0520917, 0.0520906, 0.0519579, 0.0519267, 0.0518996, 0.0518984,
    0.0517704, 0.0517489, 0.0517285, 0.0516233, 0.0516014, 0.0514977, 0.0513916,
    0.0513906, 0.0513768, 0.0513589, 0.0512823, 0.0512639, 0.0511883, 0.0511071,
    0.0510134, 0.0509782, 0.0509049, 0.050883, 0.0508237, 0.0507879, 0.0507163,
    0.0506927, 0.0506351, 0.0505851, 0.0505084, 0.050458, 0.0504572, 0.0502563,
    0.0502428, 0.0501225, 0.050067, 0.0500536, 0.0499329, 0.0498781, 0.0498598,
    0.0497517, 0.0494992, 0.0494853, 0.0494591, 0.0494049, 0.0491756, 0.0491214,
    0.049121, 0.049102, 0.0488153, 0.048743, 0.0487422, 0.0487275, 0.0485134,
    0.0484594, 0.0483639, 0.0483488, 0.048176, 0.0481596, 0.0481354, 0.0479857,
    0.0479858, 0.0479859, 0.0479863, 0.0479871, 0.0479874, 0.0478016, 0.0477664,
    0.0477213, 0.0474225, 0.0473425, 0.0472286, 0.0472075, 0.0471786, 0.0469791,
    0.0468498, 0.0468499, 0.0468501, 0.0468506, 0.046851, 0.0468516, 0.0468518,
    0.0468521, 0.0468521, 0.0468167, 0.0467425, 0.0467225, 0.0466481, 0.0465339,
    0.0464772, 0.0464739, 0.0463281, 0.046302, 0.046226, 0.0461764, 0.0460951,
    0.0459862, 0.0459672, 0.0459097, 0.0458732, 0.0457168, 0.0457027, 0.0456846,
    0.0456262, 0.0454958, 0.0454193, 0.0454018, 0.0453429, 0.0453386, 0.0453386,
    0.0453388, 0.0453398, 0.0453399, 0.0453402, 0.0453408, 0.0453008, 0.0450949,
    0.0450695, 0.0450489, 0.044962, 0.0448385, 0.0447583, 0.0447449, 0.0447443,
    0.0446834, 0.0446515, 0.0446509, 0.0445838, 0.0445486, 0.0444373, 0.044423,
    0.0443382, 0.0442975, 0.044205, 0.0441893, 0.0441802, 0.0440945, 0.0440862,
    0.0440211, 0.0439997, 0.043992, 0.043898, 0.0438267, 0.0438268, 0.0438269,
    0.0438274, 0.0438278, 0.0438285, 0.0438286, 0.043829, 0.0438078, 0.0437167,
    0.0437138, 0.0436451, 0.0436197, 0.0435271, 0.0435262, 0.0435256, 0.0434508,
    0.043426, 0.0434256, 0.0433004, 0.0432985, 0.0432046, 0.0431748, 0.0431714,
    0.0430719, 0.0430726, 0.0430726, 0.0430726, 0.0430731, 0.0430732, 0.0430742,
    0.0430588, 0.0430531, 0.0429583, 0.0428892, 0.0428701, 0.0428634, 0.042696,
    0.0426664, 0.0425746, 0.04255, 0.0424129, 0.0423172, 0.0423173, 0.0423173,
    0.0423177, 0.042319, 0.042319, 0.0423194, 0.04232, 0.0423201, 0.0423202,
    0.0423207, 0.0423212, 0.0423217, 0.0423217, 0.0422044, 0.04212, 0.0420156,
    0.0419458, 0.0419435, 0.0418008, 0.0416616, 0.0415672, 0.0415647, 0.0415647,
    0.0415648, 0.0415654, 0.0415659, 0.0415664, 0.0415664, 0.0415669, 0.0415463,
    0.0414613, 0.0414523, 0.0412843, 0.0412637, 0.0411887, 0.0411779, 0.0410749,
    0.0410002, 0.0409891, 0.0409806, 0.0408863, 0.0408105, 0.040811, 0.0408111,
    0.0408111, 0.0408116, 0.0408122, 0.0408122, 0.0408123, 0.0408127, 0.0408134,
    0.0408134, 0.0408139, 0.0408145, 0.040815, 0.0408127, 0.0408035, 0.0406666,
    0.0405514, 0.0405404, 0.0404362, 0.0404362, 0.0404373, 0.0404374, 0.040438,
    0.0404385, 0.0404353, 0.0403362, 0.0402455, 0.0401476, 0.0401383, 0.0400602,
    0.0400608, 0.0400614, 0.0400615, 0.0400625, 0.0400625, 0.0400625, 0.0400626,
    0.0400626, 0.0400632, 0.0400632, 0.0400637, 0.0400642, 0.0400643, 0.0400648,
    0.0400602, 0.0400524, 0.0400521, 0.0400518, 0.0399647, 0.0399584, 0.0398649,
    0.0398643, 0.0397761, 0.0396865, 0.0396866, 0.0396866, 0.0396866, 0.0396872,
    0.0396872, 0.0396877, 0.0396877, 0.0396877, 0.0396882, 0.0396883, 0.0396883,
    0.0396883, 0.0396883, 0.0396883, 0.0396888, 0.0396889, 0.0396889, 0.0396889,
    0.0396889, 0.0396889, 0.0396889, 0.0396889, 0.0396895, 0.0396895, 0.0396895,
    0.0396895, 0.0396895, 0.0396895, 0.03969, 0.0396901, 0.0396901, 0.0396901,
    0.0396901, 0.0396901, 0.0396905, 0.0396905, 0.0396907, 0.0396907, 0.0396907,
    0.0396907, 0.0396912, 0.0396912, 0.0396917, 0.0396923, 0.0396924, 0.0396924,
    0.0396928, 0.0396899, 0.0396581, 0.0396566, 0.0395606, 0.0395592, 0.0394631,
    0.0394617, 0.0394065, 0.0394031, 0.0393657, 0.0393642, 0.0393145, 0.0393148,
    0.0393148, 0.0393151, 0.0393154, 0.039316, 0.039316, 0.0393163, 0.0393163,
    0.0393166, 0.0393168, 0.0393171, 0.0393174, 0.039318, 0.0393183, 0.0393185,
    0.0393188, 0.0393191, 0.0393194, 0.0393197, 0.0393199, 0.03932, 0.0393203,
    0.0393205, 0.0393205, 0.0393208, 0.0393211, 0.0393211, 0.0393214, 0.0393216,
    0.0393222, 0.0393222, 0.0393222, 0.0393225, 0.0393225, 0.0393227, 0.0393227,
    0.0393233, 0.0393233, 0.0393236, 0.0393236, 0.0393238, 0.0393244, 0.0393248,
    0.0393248, 0.0393249, 0.0393249, 0.0393255, 0.0393255, 0.0393259, 0.0393259,
    0.039326, 0.0393265, 0.0393266, 0.039327, 0.0393272, 0.0393272, 0.0393277,
    0.0393277, 0.0393282, 0.0393282, 0.0393283, 0.0393287, 0.0393288, 0.0393299,
    0.0393305, 0.0393305, 0.0393305, 0.039331, 0.0393321, 0.0393327, 0.0393328,
    0.0393328, 0.0393333, 0.0393338, 0.0393345, 0.039335, 0.0394246, 0.0394431,
    0.0395335, 0.0396153, 0.0397178, 0.0397183, 0.039719, 0.0397195, 0.0397201,
    0.0397207, 0.0397212, 0.0397213, 0.0397224, 0.0398383, 0.039858, 0.0401046,
    0.0401911, 0.0403019, 0.0404053, 0.0404776, 0.0404874, 0.0406845, 0.0407643,
    0.0408702, 0.0408707, 0.0408713, 0.0408714, 0.0408719, 0.0409565, 0.0410686,
    0.0412432, 0.0412547, 0.0415161, 0.0416208, 0.0416369, 0.0416665, 0.0422185,
    0.042375, 0.0424002, 0.0424014, 0.0424014, 0.0424018, 0.0424019, 0.0429821,
    0.0431397, 0.0431652, 0.0432093, 0.043894, 0.043928, 0.0439313, 0.0441368,
    0.0442026, 0.044218, 0.0443108, 0.0445323, 0.0448682, 0.0450735, 0.045077,
    0.0451848, 0.04544, 0.0454557, 0.0454949, 0.0456514, 0.0458148, 0.046197,
    0.046219, 0.0462739, 0.0464792, 0.0466992, 0.0469818, 0.0472087, 0.0472099,
    0.0472934, 0.0474654, 0.0474952, 0.0477445, 0.0479749, 0.0482305, 0.048232,
    0.0483128, 0.0484876, 0.0485073, 0.0485094, 0.0487527, 0.0487534, 0.0488816,
    0.0488895, 0.0489599, 0.049159, 0.0491799, 0.0494464, 0.0497338, 0.0500207,
    0.0500333, 0.0500372, 0.0500987, 0.0502777, 0.0505332, 0.0505347, 0.0507903,
    0.0507961, 0.0507999, 0.0510458, 0.0510474, 0.0511181, 0.0513029, 0.0515584,
    0.0515588, 0.0515606, 0.0519434, 0.0519451, 0.0519457, 0.051948, 0.0523308,
    0.0523332, 0.0527021, 0.0527114, 0.0527129, 0.0527713, 0.0529599, 0.0529684,
    0.0532236, 0.0532251, 0.0534648, 0.0534886, 0.0534909, 0.0538505, 0.0538737,
    0.0542565, 0.0543336, 0.0546081, 0.054629, 0.0546305, 0.0548656, 0.054886,
    0.0548876, 0.0551431, 0.0553708, 0.0554125, 0.0557571, 0.0557954, 0.0557971,
    0.0561799, 0.0561822, 0.0561845, 0.0562413, 0.0565141, 0.0565674, 0.0565697,
    0.0568998, 0.0569525, 0.0572763, 0.0573157, 0.0573172, 0.0575728, 0.0575743,
    0.0577881, 0.0578299, 0.058039, 0.0581085, 0.0581427, 0.0588058, 0.0588765,
    0.0591823, 0.0592593, 0.0596595, 0.0599491, 0.0600226, 0.0600244, 0.0603255,
    0.0604072, 0.0604147, 0.06079, 0.0610918, 0.0611728, 0.0614688, 0.061551,
    0.0615557, 0.0619385, 0.0619408, 0.062235, 0.0623063, 0.0623236, 0.0626121,
    0.0627535, 0.0632853, 0.0633261, 0.0637547, 0.0637582, 0.0638515, 0.0638538,
    0.0642366, 0.0645777, 0.0646194, 0.064898, 0.0649009, 0.0650023, 0.0653851,
    0.0656637, 0.0657135, 0.0657679, 0.0660413, 0.0661115, 0.0667744, 0.067184,
    0.0671869, 0.0672934, 0.0672957, 0.0676039, 0.0676786, 0.0679496, 0.0683272,
    0.0684413, 0.0687402, 0.0688241, 0.0688265, 0.0690929, 0.0692093, 0.0694705,
    0.0700475, 0.0702227, 0.0706132, 0.0707954, 0.071191, 0.0712188, 0.0713689,
    0.0717559, 0.0722609, 0.0725221, 0.0726437, 0.0728991, 0.0732848, 0.0734065,
    0.0740424, 0.0740511, 0.0742324, 0.0748059, 0.0751851, 0.0751938, 0.0752085,
    0.0763278, 0.0763365, 0.0765178, 0.0774705, 0.0778568, 0.0783577, 0.0786137,
    0.0786222, 0.0796399, 0.0797934, 0.0801375, 0.0801462, 0.0812802, 0.0812889,
    0.0820281, 0.0824229, 0.0830016, 0.0831656, 0.0835656, 0.0839519, 0.0840579,
    0.0847083, 0.0847088, 0.0848702, 0.0852876, 0.0858515, 0.0859904, 0.0863662,
    0.0864942, 0.0873753, 0.087522, 0.0879532, 0.088518, 0.0886586, 0.0892251,
    0.0896607, 0.0896636, 0.0900325, 0.0901292, 0.0905074, 0.090804, 0.0908086,
    0.0909669, 0.0917216, 0.0923272, 0.0928998, 0.09301, 0.0934699, 0.0935766,
    0.0946126, 0.0946797, 0.0949751, 0.0950579, 0.0954367, 0.0957552, 0.0964144,
    0.0968985, 0.0970083, 0.097763, 0.0984148, 0.0984217, 0.0988509, 0.0992291,
    0.0995372, 0.0995609, 0.099565, 0.100199, 0.100708, 0.100766, 0.101332,
    0.10184, 0.10185, 0.101899, 0.102993, 0.103032, 0.103995, 0.104042,
    0.104517, 0.104551, 0.105034, 0.105642, 0.10566, 0.105678, 0.106056,
    0.106803, 0.106821, 0.107354, 0.107388, 0.107945, 0.107955, 0.108461,
    0.109088, 0.109091, 0.109453, 0.109467, 0.109469, 0.110231, 0.110749,
    0.110793, 0.111347, 0.11136, 0.111374, 0.111866, 0.112369, 0.112872,
    0.112898, 0.113244, 0.11326, 0.113639, 0.11366, 0.114135, 0.114639,
    0.114647, 0.11514, 0.115141, 0.115143, 0.115184, 0.115529, 0.115946,
    0.116288, 0.116304, 0.116667, 0.117045, 0.117059, 0.117089, 0.117534,
    0.117565, 0.118038, 0.118613, 0.11894, 0.119319, 0.119349, 0.119375,
    0.120078, 0.120484, 0.120519, 0.120836, 0.120871, 0.121215, 0.121662,
    0.121974, 0.122014, 0.122382, 0.122733, 0.122805, 0.123537, 0.123873,
    0.123899, 0.123948, 0.125013, 0.125059, 0.125092, 0.125415, 0.125854,
    0.126308, 0.126319, 0.12634, 0.126616, 0.127126, 0.127163, 0.127421,
    0.12776, 0.128056, 0.128488, 0.128522, 0.128674, 0.12886, 0.129078,
    0.129286, 0.129479, 0.129992, 0.130025, 0.130049, 0.13024, 0.130747,
    0.130788, 0.130811, 0.131254, 0.131574, 0.131804, 0.132014, 0.13228,
    0.132337, 0.132406, 0.132519, 0.132693, 0.13272, 0.132914, 0.133156,
    0.133202, 0.133482, 0.133554, 0.133742, 0.13376, 0.13384, 0.133865,
    0.134082, 0.134084, 0.134234, 0.134247, 0.134762, 0.134954, 0.134991,
    0.135011, 0.135011, 0.135011, 0.135012, 0.135012, 0.135012, 0.135012,
    0.135012, 0.135012, 0.135088, 0.13509, 0.135098, 0.135173, 0.135187,
    0.135188, 0.135269, 0.135285, 0.135287, 0.135288, 0.135385, 0.135385,
    0.135387, 0.135395, 0.135484, 0.135485, 0.135555, 0.135583, 0.135584,
    0.135681, 0.135768, 0.135778, 0.135778, 0.135778, 0.135778, 0.135779,
    0.135779, 0.13578, 0.13578, 0.13578, 0.13578, 0.13578, 0.13578, 0.13578,
    0.13578, 0.13578, 0.13578, 0.13578, 0.135781, 0.135781, 0.135781, 0.135781,
    0.135781, 0.135781, 0.135782, 0.135782, 0.135782, 0.135782, 0.135782,
    0.135782, 0.135783, 0.135783, 0.135783, 0.135783, 0.135783, 0.135783,
    0.135784, 0.135784, 0.135784, 0.135784, 0.135784, 0.135784, 0.135784,
    0.135784, 0.135784, 0.135784, 0.135785, 0.135785, 0.135785, 0.135785,
    0.135785, 0.135785, 0.135785, 0.135785, 0.135785, 0.135785, 0.135785,
    0.135785, 0.135786, 0.135786, 0.135786, 0.135786, 0.135786, 0.135786,
    0.135786, 0.135786, 0.135786, 0.135786, 0.135787, 0.135787, 0.135787,
    0.135787, 0.135787, 0.135787, 0.135787, 0.135788, 0.135788, 0.135788,
    0.135788, 0.135788, 0.135788, 0.135788, 0.135788, 0.135788, 0.135789,
    0.135789, 0.135789, 0.135789, 0.135789, 0.135789, 0.135789, 0.135789,
    0.135789, 0.135789, 0.135789, 0.135789, 0.13579, 0.13579, 0.13579, 0.13579,
    0.13579, 0.13579, 0.13579, 0.13579, 0.13579, 0.13579, 0.135791, 0.135791,
    0.135791, 0.135791, 0.135791, 0.135791, 0.135791, 0.135791, 0.135791,
    0.135791, 0.135792, 0.135792, 0.135709, 0.135708, 0.135707, 0.135589,
    0.135463, 0.135456, 0.135413, 0.135413, 0.135414, 0.135414, 0.135415,
    0.135415, 0.135415, 0.135415, 0.135415, 0.135416, 0.135417, 0.135417,
    0.135417, 0.135417, 0.135417, 0.135417, 0.135418, 0.135418, 0.135419,
    0.13542, 0.13542, 0.135366, 0.135272, 0.135259, 0.135083, 0.135052,
    0.135041, 0.134895, 0.13488, 0.134706, 0.134674, 0.134663, 0.134664,
    0.134664, 0.134665, 0.134665, 0.134598, 0.134519, 0.134298, 0.134287,
    0.134236, 0.134219, 0.133952, 0.133935, 0.133919, 0.133908, 0.13391,
    0.13391, 0.133911, 0.133911, 0.133911, 0.133911, 0.133912, 0.133863,
    0.133843, 0.133828, 0.133674, 0.133559, 0.133544, 0.133534, 0.133391,
    0.133369, 0.133202, 0.13318, 0.133166, 0.133156, 0.132839, 0.132808,
    0.13279, 0.132777, 0.132635, 0.132611, 0.132515, 0.132446, 0.132408,
    0.132399, 0.132399, 0.1324, 0.1324, 0.132401, 0.132401, 0.132302, 0.13221,
    0.132049, 0.132033, 0.132022, 0.131924, 0.131682, 0.131544, 0.131491,
    0.131279, 0.131263, 0.131214, 0.131187, 0.131024, 0.130997, 0.130987,
    0.130902, 0.130885, 0.130886, 0.130886, 0.130887, 0.130887, 0.130887,
    0.130887, 0.130835, 0.130713, 0.130705, 0.130645, 0.130618, 0.13055,
    0.130509, 0.130437, 0.130401, 0.130391, 0.130183, 0.130148, 0.13013,
    0.130075, 0.130041, 0.12998, 0.129954, 0.129885, 0.129858, 0.129851,
    0.129789, 0.129763, 0.129752, 0.129694, 0.129694, 0.129693, 0.129598,
    0.129597, 0.129597, 0.129596, 0.129574, 0.129567, 0.129501, 0.1295,
    0.129479, 0.129377, 0.129373, 0.12929, 0.129289, 0.12926, 0.129162,
    0.129133, 0.129126, 0.129035, 0.128995, 0.128864, 0.128822, 0.128811,
    0.128673, 0.128672, 0.128481, 0.12848, 0.128479, 0.128442, 0.12843,
    0.128288, 0.128286, 0.128285, 0.128251, 0.128236, 0.128142, 0.128141,
    0.12814, 0.128013, 0.128012, 0.128011, 0.127992, 0.127985, 0.127884,
    0.127884, 0.127883, 0.127865, 0.127857, 0.127857, 0.127857, 0.127858,
    0.127858, 0.127858, 0.127858, 0.127859, 0.127859, 0.127859, 0.127859,
    0.127859, 0.127859, 0.127859, 0.127859, 0.127642, 0.127641, 0.127618,
    0.127386, 0.127353, 0.127131, 0.12711, 0.1271, 0.127016, 0.127016, 0.126921,
    0.12691, 0.126826, 0.12682, 0.12673, 0.12673, 0.126722, 0.126605, 0.126605,
    0.126604, 0.126603, 0.126602, 0.126594, 0.126475, 0.126474, 0.126474,
    0.126473, 0.126346, 0.126343, 0.126251, 0.12625, 0.126247, 0.126157,
    0.126155, 0.126154, 0.126059, 0.125968, 0.125965, 0.125964, 0.125962,
    0.125844, 0.125837, 0.125718, 0.125709, 0.125709, 0.125593, 0.125586,
    0.125581, 0.12539, 0.125217, 0.125199, 0.125028, 0.12501, 0.125008, 0.12484,
    0.124827, 0.12482, 0.12471, 0.124696, 0.124693, 0.124584, 0.124566,
    0.124459, 0.124448, 0.124444, 0.124441, 0.124362, 0.124346, 0.124269,
    0.124268, 0.124254, 0.124251, 0.124175, 0.124156, 0.124081, 0.124081,
    0.12407, 0.123959, 0.123937, 0.123931, 0.123834, 0.123834, 0.123804,
    0.123709, 0.123691, 0.12361, 0.123591, 0.123586, 0.123491, 0.123422,
    0.123401, 0.123396, 0.123328, 0.123313, 0.12328, 0.1231, 0.123041, 0.123026,
    0.122849, 0.122597, 0.122554, 0.12254, 0.122451, 0.122381, 0.12235,
    0.122287, 0.122262, 0.122175, 0.122156, 0.121946, 0.121911, 0.121903,
    0.121797, 0.121643, 0.121575, 0.121398, 0.121195, 0.121074, 0.121038,
    0.120898, 0.120763, 0.120659, 0.120582, 0.120554, 0.120453, 0.120281,
    0.120178, 0.12014, 0.120132, 0.119902, 0.119858, 0.119619, 0.119443,
    0.119143, 0.119126, 0.118942, 0.118938, 0.118876, 0.118764, 0.118723,
    0.118305, 0.118226, 0.118218, 0.118005, 0.117896, 0.117739, 0.117709,
    0.117707, 0.117627, 0.117482, 0.117357, 0.117271, 0.117248, 0.117221,
    0.117032, 0.116653, 0.116652, 0.116523, 0.116489, 0.116346, 0.11611,
    0.116086, 0.116083, 0.115954, 0.115897, 0.115709, 0.11552, 0.115513,
    0.115352, 0.115247, 0.115212, 0.115086, 0.114973, 0.114953, 0.114834,
    0.114742, 0.114708, 0.114594, 0.114491, 0.114484, 0.114421, 0.114302,
    0.114216, 0.114186, 0.114154, 0.113521, 0.113427, 0.113076, 0.11307,
    0.113069, 0.112976, 0.112965, 0.112808, 0.112787, 0.112698, 0.112691,
    0.112566, 0.11255, 0.11234, 0.112319, 0.11231, 0.112308, 0.112058, 0.112021,
    0.1116, 0.11156, 0.111557, 0.111385, 0.111368, 0.111353, 0.111274, 0.111181,
    0.11118, 0.111074, 0.111054, 0.11093, 0.110929, 0.110905, 0.110821,
    0.110804, 0.110803, 0.110449, 0.110426, 0.110237, 0.110197, 0.110069,
    0.110049, 0.110044, 0.109921, 0.109807, 0.109796, 0.10968, 0.10967,
    0.109665, 0.109628, 0.109307, 0.109294, 0.109116, 0.109106, 0.108917,
    0.108906, 0.108881, 0.108793, 0.108661, 0.108539, 0.108527, 0.108503,
    0.108409, 0.108286, 0.108284, 0.108158, 0.108149, 0.107916, 0.107846,
    0.107662, 0.10741, 0.107408, 0.107389, 0.107023, 0.106972, 0.106836,
    0.10663, 0.106515, 0.106479, 0.106386, 0.106263, 0.106252, 0.106025,
    0.105884, 0.1057, 0.105506, 0.105493, 0.105379, 0.105376, 0.105342,
    0.105124, 0.105114, 0.104944, 0.104888, 0.10475, 0.104561, 0.104375,
    0.104355, 0.104331, 0.10424, 0.104116, 0.103988, 0.103977, 0.103952,
    0.103863, 0.103738, 0.1037, 0.103611, 0.103598, 0.103429, 0.103239,
    0.103183, 0.10305, 0.102839, 0.102727, 0.102727, 0.10269, 0.102475, 0.10246,
    0.102292, 0.102236, 0.101914, 0.101858, 0.101723, 0.101701, 0.101404,
    0.100972, 0.100942, 0.10083, 0.100794, 0.100563, 0.10048, 0.100479,
    0.100453, 0.100196, 0.100185, 0.100143, 0.0999613, 0.0996374, 0.0994256,
    0.099342, 0.0992213, 0.0991531, 0.0990586, 0.0990474, 0.0987539, 0.0985715,
    0.0983185, 0.0982881, 0.0980663, 0.097995, 0.0978145, 0.0978126, 0.0975627,
    0.0975287, 0.0975089, 0.0974201, 0.0974182, 0.0972953, 0.0972945, 0.0971697,
    0.0971661, 0.0971499, 0.096991, 0.0969309, 0.0968023, 0.0967954, 0.0966134,
    0.0963912, 0.0963719, 0.0962862, 0.0962854, 0.0962846, 0.0962839, 0.0962819,
    0.0961568, 0.0961562, 0.096156, 0.0961552, 0.0961545, 0.0961537, 0.0961529,
    0.0960258, 0.096025, 0.0960243, 0.0960235, 0.0960227, 0.096022, 0.0960212,
    0.0960123, 0.0959837, 0.09585, 0.0958347, 0.0958335, 0.0958323, 0.0958312,
    0.09583, 0.095639, 0.0956379, 0.0956367, 0.0956356, 0.0954712, 0.0954446,
    0.0954434, 0.0954423, 0.0954411, 0.0954148, 0.0952536, 0.0952513, 0.0952505,
    0.0952497, 0.095249, 0.0952482, 0.0952474, 0.0951465, 0.0951203, 0.0951196,
    0.0951188, 0.095118, 0.0949909, 0.0949902, 0.0949894, 0.0949886, 0.0949823,
    0.0948944, 0.0948748, 0.0948615, 0.0948607, 0.09486, 0.0948592, 0.0947321,
    0.0947313, 0.0947306, 0.0947298, 0.094729, 0.0946425, 0.0946019, 0.0946011,
    0.0946004, 0.0945996, 0.094496, 0.0944679, 0.0944607, 0.0944596, 0.0944584,
    0.0944572, 0.0943373, 0.0942663, 0.0942651, 0.0942639, 0.0942628, 0.0940718,
    0.0940706, 0.0940695, 0.0940683, 0.0940672, 0.094066, 0.0939585, 0.0938987,
    0.093875, 0.0938739, 0.0938727, 0.0938716, 0.0938704, 0.0937372, 0.0936987,
    0.0936326, 0.0935716, 0.093507, 0.0934447, 0.0933584, 0.0933301, 0.093298,
    0.0932029, 0.0931116, 0.0931105, 0.0931093, 0.0930143, 0.0929276, 0.0929264,
    0.0929253, 0.0929241, 0.092923, 0.0928256, 0.0927424, 0.0927412, 0.0927401,
    0.0927389, 0.0926369, 0.0925996, 0.0925618, 0.0925445, 0.0925429, 0.0925414,
    0.0925399, 0.0925383, 0.0922973, 0.0922957, 0.0922942, 0.0922927, 0.0921438,
    0.0920532, 0.0920516, 0.0920501, 0.0920485, 0.092047, 0.0920455, 0.091892,
    0.0918403, 0.0918247, 0.091824, 0.0918232, 0.0918224, 0.0918216, 0.0918213,
    0.0918209, 0.0917022, 0.0917015, 0.0917007, 0.0916999, 0.0916992, 0.091614,
    0.091579, 0.0915782, 0.0915774, 0.0915767, 0.0914615, 0.0914517, 0.0914505,
    0.0914494, 0.0913121, 0.0912665, 0.0912653, 0.0912641, 0.091263, 0.0912433,
    0.0910824, 0.0910812, 0.0910801, 0.0910789, 0.0910778, 0.090933, 0.0908983,
    0.0908972, 0.090896, 0.0908949, 0.0908937, 0.0907131, 0.090712, 0.0907108,
    0.0907097, 0.0907027, 0.0906839, 0.0906041, 0.0905872, 0.0905864, 0.0905856,
    0.0905849, 0.0905841, 0.0904647, 0.0904639, 0.0904631, 0.0904624, 0.0904616,
    0.0903522, 0.0903399, 0.0903391, 0.0903239, 0.0902509, 0.0902152, 0.0901569,
    0.0901506, 0.0900629, 0.0900562, 0.0899457, 0.089754, 0.089734, 0.0896549,
    0.089479, 0.0892488, 0.0892241, 0.0891863, 0.0890412, 0.0889654, 0.0889517,
    0.0888246, 0.0888075, 0.0884761, 0.0884527, 0.0883998, 0.0880973, 0.0880722,
    0.0880488, 0.0879038, 0.0878109, 0.0877028, 0.08767, 0.0872303, 0.0871914,
    0.0871254, 0.0869106, 0.0868412, 0.0868258, 0.0866126, 0.0865577, 0.0865416,
    0.0865324, 0.0860947, 0.0857958, 0.085773, 0.0857585, 0.0856099, 0.0855919,
    0.0854212, 0.0853948, 0.0853559, 0.0851615, 0.084706, 0.0846536, 0.0846355,
    0.0846159, 0.0842922, 0.0842649, 0.0842567, 0.0841472, 0.0840732, 0.0839999,
    0.0838784, 0.0836983, 0.0836389, 0.0835849, 0.0831307, 0.0831191, 0.0829394,
    0.0826228, 0.0825715, 0.0823598, 0.0822683, 0.0821979, 0.0820786, 0.0820604,
    0.0819815, 0.0815484, 0.0814806, 0.0812222, 0.0812063, 0.0811289, 0.0810601,
    0.0809393, 0.0808703, 0.0808439, 0.0808011, 0.080591, 0.0804066, 0.0801517,
    0.0800846, 0.0800838, 0.0800635, 0.079991, 0.0799567, 0.0798296, 0.0797375,
    0.0797058, 0.0797034, 0.0796903, 0.0796345, 0.079634, 0.0795388, 0.0795137,
    0.0794186, 0.0794069, 0.0793492, 0.0793276, 0.0791305, 0.079063, 0.0790345,
    0.0788756, 0.0788081, 0.0785682, 0.0785626, 0.0785535, 0.0784934, 0.0783982,
    0.0783976, 0.078373, 0.0783024, 0.0782778, 0.0782702, 0.07819, 0.0781802,
    0.0780867, 0.078086, 0.078045, 0.0779589, 0.0779581, 0.0779573, 0.0779267,
    0.0778304, 0.0778112, 0.0777941, 0.077784, 0.0776031, 0.0774592, 0.0774581,
    0.0774121, 0.0772671, 0.0772659, 0.0772154, 0.0770749, 0.0770524, 0.0770243,
    0.0767693, 0.0767643, 0.0765742, 0.0765727, 0.0765711, 0.0765696, 0.0763147,
    0.0763131, 0.0763116, 0.07631, 0.0762931, 0.0762773, 0.0762762, 0.0761744,
    0.0761502, 0.0760473, 0.0760468, 0.0760241, 0.0760231, 0.0759197, 0.0759143,
    0.0757314, 0.0757302, 0.0757291, 0.0756973, 0.0755381, 0.0755369, 0.0755106,
    0.0753164, 0.0751561, 0.0751555, 0.0751399, 0.0751355, 0.0750288, 0.075028,
    0.0748878, 0.0748823, 0.0747767, 0.0747751, 0.0747747, 0.0746795, 0.0746712,
    0.074666, 0.0745843, 0.0745838, 0.0744886, 0.074488, 0.0744874, 0.0744825,
    0.0744763, 0.0743985, 0.0743819, 0.0743803, 0.0743715, 0.0741254, 0.0741239,
    0.0741223, 0.0741208, 0.0741192, 0.0740988, 0.0738663, 0.0738643, 0.0738628,
    0.0738613, 0.0738597, 0.0736391, 0.073622, 0.0736212, 0.0736151, 0.0735005,
    0.0734941, 0.0733747, 0.073367, 0.0733663, 0.0733618, 0.0732603, 0.0732445,
    0.0732439, 0.0731574, 0.0731487, 0.0730633, 0.0730521, 0.0729591, 0.0728821,
    0.072862, 0.0728335, 0.0726102, 0.0725786, 0.072324, 0.0723237, 0.0721227,
    0.0721161, 0.0721026, 0.072102, 0.0720221, 0.0720091, 0.0720068, 0.0720063,
    0.0719111, 0.0719105, 0.0718333, 0.0718155, 0.0717445, 0.0717376, 0.0717195,
    0.0717122, 0.0715851, 0.0715843, 0.0715835, 0.0714857, 0.0714564, 0.0713657,
    0.0713267, 0.0712928, 0.0711007, 0.0710749, 0.0710379, 0.0708489, 0.070783,
    0.0706063, 0.0705925, 0.0705771, 0.0705083, 0.0704985, 0.0704141, 0.0703875,
    0.0703869, 0.0703099, 0.0702917, 0.0702281, 0.0702246, 0.0702118, 0.070186,
    0.0700862, 0.0700589, 0.0699726, 0.0699604, 0.069932, 0.0698493, 0.0698458,
    0.0698276, 0.0697825, 0.0696571, 0.0696413, 0.0696401, 0.0696389, 0.0695916,
    0.0694549, 0.0694537, 0.0694526, 0.0692786, 0.0692662, 0.0692107, 0.0690905,
    0.06909, 0.0690819, 0.0690436, 0.0689563, 0.0689165, 0.0688381, 0.0688307,
    0.0687894, 0.0687117, 0.0686177, 0.0686121, 0.0685801, 0.0685235, 0.0684849,
    0.0684295, 0.0684235, 0.0683899, 0.0683335, 0.0682297, 0.0680855, 0.0680689,
    0.0679748, 0.0677199, 0.0675799, 0.0675741, 0.0675703, 0.0674823, 0.0674389,
    0.0673881, 0.0672868, 0.0672492, 0.0671959, 0.0670744, 0.0670138, 0.0669488,
    0.0668231, 0.0668171, 0.0668096, 0.0665423, 0.0664475, 0.0660687, 0.0660583,
    0.0660529, 0.0660008, 0.0658134, 0.0656878, 0.0656795, 0.0656752, 0.0656366,
    0.0654969, 0.0653531, 0.0653083, 0.0653013, 0.0652889, 0.0649349, 0.0648142,
    0.0645419, 0.0644856, 0.0644258, 0.0644089, 0.0642335, 0.0641737, 0.0641631,
    0.0640272, 0.0639824, 0.0639686, 0.063933, 0.0637937, 0.0637849, 0.0637307,
    0.0635249, 0.0634788, 0.0634197, 0.0634061, 0.0633266, 0.0630481, 0.0629478,
    0.0628256, 0.0626693, 0.0626473, 0.0625954, 0.0624698, 0.0624098, 0.0623871,
    0.0622685, 0.0622305, 0.062091, 0.0620419, 0.061997, 0.0619791, 0.0619477,
    0.0619029, 0.0618903, 0.0617814, 0.0617151, 0.0615295, 0.0615114, 0.0615058,
    0.0614753, 0.0612865, 0.0612416, 0.0612223, 0.0611332, 0.0610859, 0.0610259,
    0.0609603, 0.0607738, 0.0607544, 0.0607437, 0.060685, 0.0604048, 0.0603065,
    0.0601745, 0.0599956, 0.0599504, 0.0598895, 0.0596985, 0.0596372, 0.0596168,
    0.0596113, 0.059584, 0.0594435, 0.0593952, 0.0593018, 0.0593012, 0.0592386,
    0.0592307, 0.0591974, 0.0591338, 0.0589461, 0.0589455, 0.0588817, 0.0588598,
    0.0588471, 0.058802, 0.0588008, 0.0586133, 0.0585142, 0.0582767, 0.0582348,
    0.0581354, 0.058101, 0.0580736, 0.0579796, 0.0579294, 0.0579045, 0.0578855,
    0.0578354, 0.0577228, 0.0576881, 0.0575858, 0.0575626, 0.0574947, 0.0573439,
    0.0573195, 0.0573189, 0.0572682, 0.057241, 0.057226, 0.0572254, 0.057132,
    0.0570793, 0.0570512, 0.0569657, 0.0569359, 0.0568655, 0.0568265, 0.0568111,
    0.0568103, 0.0566996, 0.0566853, 0.0566134, 0.0565869, 0.0565761, 0.056472,
    0.0564181, 0.0563863, 0.0562834, 0.0562294, 0.0562086, 0.0561927, 0.0560564,
    0.0559842, 0.0559393, 0.0559308, 0.0558298, 0.0556833, 0.0556137, 0.0556027,
    0.0554944, 0.0552329, 0.0552242, 0.0551159, 0.0550711, 0.0550712, 0.0550715,
    0.0550718, 0.0550726, 0.0550728, 0.055043, 0.0550381, 0.0548491, 0.0547411,
    0.0544741, 0.0544706, 0.0543623, 0.054314, 0.0541661, 0.054094, 0.0540421,
    0.0539352, 0.0538655, 0.0538265, 0.0538248, 0.0537715, 0.0537308, 0.0536379,
    0.0535827, 0.053557, 0.0534491, 0.0534474, 0.0532992, 0.0532605, 0.0532586,
    0.0531787, 0.0531788, 0.0531799, 0.0531799, 0.0531803, 0.0531804, 0.0529678,
    0.0529629, 0.0526641, 0.0525893, 0.0525841, 0.0524217, 0.0524076, 0.0522044,
    0.0521555, 0.0521513, 0.0520429, 0.0519385, 0.0518803, 0.0518408, 0.0517498,
    0.0517467, 0.0516646, 0.0515579, 0.0515022, 0.0514665, 0.0513693, 0.0512864,
    0.0512748, 0.051196, 0.0511452, 0.0509076, 0.0508991, 0.0508968, 0.05084,
    0.0507081, 0.0505565, 0.0505293, 0.0505294, 0.0505294, 0.0505299, 0.0505309,
    0.050531, 0.0505221, 0.0504279, 0.0503335, 0.0502749, 0.0501528, 0.050142,
    0.0501416, 0.0500168, 0.0500161, 0.0498913, 0.0498107, 0.049774, 0.0497627,
    0.0497615, 0.0497587, 0.049574, 0.049385, 0.0493798, 0.0491964, 0.0490701,
    0.0490152, 0.0490152, 0.0490152, 0.0490164, 0.0490173, 0.0490175, 0.0490125,
    0.0490094, 0.0487544, 0.0487537, 0.0487529, 0.0486748, 0.0486387, 0.0486329,
    0.048629, 0.0486284, 0.0486279, 0.0486273, 0.0485321, 0.0485315, 0.048531,
    0.0485304, 0.0484441, 0.0484352, 0.0484346, 0.0484341, 0.0483389, 0.0483383,
    0.0483377, 0.0483372, 0.0482872, 0.0482604, 0.048242, 0.0482414, 0.0482408,
    0.0481608, 0.0481457, 0.0481451, 0.0481445, 0.0481439, 0.0480488, 0.0480482,
    0.0480476, 0.048047, 0.0480033, 0.047972, 0.0479519, 0.0479513, 0.0479507,
    0.0479501, 0.0479496, 0.047949, 0.0478822, 0.0478443, 0.0478435, 0.0478428,
    0.047842, 0.0478412, 0.0477503, 0.0476245, 0.0475786, 0.0475778, 0.047577,
    0.0475382, 0.0475034, 0.0475036, 0.0475036, 0.047504, 0.0475048, 0.0475049,
    0.0475051, 0.0475054, 0.0475054, 0.0475056, 0.0474573, 0.0474087, 0.0473621,
    0.0473421, 0.0472669, 0.0472201, 0.0471745, 0.0471739, 0.0471733, 0.0471728,
    0.0471274, 0.0471255, 0.0470678, 0.047067, 0.0470663, 0.0470655, 0.0470647,
    0.0469438, 0.046943, 0.0469422, 0.0469084, 0.0468736, 0.0467486, 0.0466534,
    0.0466184, 0.0466179, 0.0466173, 0.0465843, 0.0465261, 0.0465256, 0.046525,
    0.0465244, 0.0464648, 0.0464344, 0.0464338, 0.0464333, 0.0464327, 0.0464321,
    0.0463708, 0.0463703, 0.0463421, 0.0463415, 0.046341, 0.0463404, 0.0462498,
    0.0462493, 0.0462487, 0.0462481, 0.0462056, 0.046182, 0.0461564, 0.0461558,
    0.0459934, 0.0459921, 0.0459922, 0.0459922, 0.0459922, 0.0459925, 0.0459928,
    0.0459928, 0.0459928, 0.0459928, 0.0459928, 0.0459932, 0.0459933, 0.0459933,
    0.0459933, 0.0459933, 0.0459933, 0.0459938, 0.0459846, 0.045984, 0.0459834,
    0.0459829, 0.0459233, 0.0459015, 0.0458923, 0.0458917, 0.0458911, 0.0458906,
    0.0457994, 0.0457989, 0.0457983, 0.0457128, 0.0457054, 0.0456156, 0.0456108,
    0.0455446, 0.045524, 0.0455158, 0.0453354, 0.045326, 0.0452608, 0.0452412,
    0.0452373, 0.0452287, 0.0451016, 0.0449745, 0.0448658, 0.0448585, 0.0448503,
    0.0447871, 0.0446752, 0.0446605, 0.0444866, 0.0444803, 0.0444807, 0.0444809,
    0.0444814, 0.044482, 0.0444824, 0.0444825, 0.0444825, 0.0443426, 0.0442403,
    0.0441037, 0.0441038, 0.0441042, 0.0441042, 0.0441048, 0.0441054, 0.0441055,
    0.0441059, 0.044106, 0.0440212, 0.0439051, 0.0438324, 0.0437495, 0.0437278,
    0.0437155, 0.0435491, 0.0435258, 0.0434656, 0.0433495, 0.0432372, 0.0432053,
    0.0431251, 0.0429707, 0.0429708, 0.0429718, 0.0429719, 0.0429723, 0.042973,
    0.0429731, 0.0429735, 0.042974, 0.0429742, 0.0429753, 0.0429593, 0.042901,
    0.0428919, 0.0428642, 0.0426745, 0.042617, 0.042597, 0.0425734, 0.0424855,
    0.0423201, 0.0422182, 0.0422187, 0.0422189, 0.0422193, 0.04222, 0.0422204,
    0.0422205, 0.0422006, 0.0421365, 0.0421054, 0.0419471, 0.0419157, 0.0418612,
    0.0418422, 0.0418424, 0.0418433, 0.0418435, 0.0418438, 0.0418441, 0.0418445,
    0.0418445, 0.0417266, 0.0416642, 0.0416316, 0.0415793, 0.0415691, 0.0414663,
    0.0414664, 0.0414668, 0.0414673, 0.0414674, 0.0414674, 0.0414676, 0.0414681,
    0.0414685, 0.0414685, 0.041469, 0.0414691, 0.0414693, 0.0414696, 0.0414697,
    0.0414698, 0.0414702, 0.0413935, 0.0413794, 0.0413486, 0.0412844, 0.0412534,
    0.0411892, 0.0411582, 0.0411093, 0.041094, 0.041092, 0.0409685, 0.0409042,
    0.0408734, 0.0408251, 0.0408091, 0.0408086, 0.0407783, 0.0407138, 0.0407138,
    0.0407142, 0.0407143, 0.0407145, 0.0407149, 0.0407155, 0.0407155, 0.0407157,
    0.0407159, 0.040716, 0.0407129, 0.0406844, 0.0406177, 0.0405892, 0.0405225,
    0.0404477, 0.0403994, 0.0403378, 0.0403378, 0.040338, 0.0403384, 0.0403388,
    0.0403391, 0.0403395, 0.0403395, 0.0403397, 0.0403401, 0.0403405, 0.0403408,
    0.0403412, 0.0403414, 0.0403418, 0.0403341, 0.0402614, 0.0402391, 0.040211,
    0.0401439, 0.0400488, 0.0400482, 0.0400214, 0.0399775, 0.0399635, 0.0399636,
    0.0399636, 0.0399642, 0.0399643, 0.0399652, 0.0399653, 0.0399653, 0.0399655,
    0.0399658, 0.0399659, 0.0399665, 0.0399666, 0.0399669, 0.039967, 0.0399676,
    0.0399677, 0.0399681, 0.0399682, 0.0399682, 0.0399682, 0.0399688, 0.0399688,
    0.0399688, 0.0399691, 0.0399693, 0.0399693, 0.0399695, 0.0399699, 0.0399699,
    0.0399704, 0.0399705, 0.0399705, 0.0399705, 0.0399706, 0.0399708, 0.0399711,
    0.0399711, 0.0399716, 0.0399717, 0.039972, 0.0399722, 0.0399722, 0.0399723,
    0.0399726, 0.0399728, 0.0399734, 0.0399734, 0.0399734, 0.0399737, 0.0399739,
    0.039974, 0.0399745, 0.0399745, 0.0399745, 0.0399745, 0.0399746, 0.0399749,
    0.0399351, 0.0399343, 0.0399336, 0.0399328, 0.0399286, 0.0398638, 0.0398057,
    0.0398049, 0.0398042, 0.039803, 0.0396774, 0.039677, 0.0396763, 0.0396107,
    0.0395961, 0.0395963, 0.0395963, 0.0395963, 0.0395963, 0.0395963, 0.0395963,
    0.0395969, 0.0395969, 0.0395969, 0.0395969, 0.0395969, 0.0395974, 0.0395974,
    0.0395975, 0.0395975, 0.0395977, 0.039598, 0.039598, 0.0395981, 0.0395984,
    0.0395986, 0.0395986, 0.0395986, 0.0395991, 0.0395992, 0.0395992, 0.0395994,
    0.0395997, 0.0395998, 0.0396002, 0.0396003, 0.0396006, 0.0396008, 0.0396009,
    0.0396014, 0.0396015, 0.0396017, 0.0396019, 0.0396021, 0.0396025, 0.0396026,
    0.0396029, 0.0396031, 0.0396032, 0.0396032, 0.0396034, 0.0396036, 0.0396038,
    0.0396038, 0.0396038, 0.0396038, 0.0396042, 0.0396044, 0.0396044, 0.0396048,
    0.0396049, 0.0396052, 0.0396053, 0.0396055, 0.0396057, 0.0396061, 0.0396065,
    0.0396067, 0.039607, 0.0396072, 0.0396075, 0.039608, 0.0396084, 0.0396087,
    0.0396095, 0.0396097, 0.0396097, 0.0396107, 0.039611, 0.0396114, 0.039612,
    0.0396124, 0.0396133, 0.0396137, 0.0396143, 0.0396146, 0.0396154, 0.0396156,
    0.0396166, 0.0396744, 0.0397992, 0.0398305, 0.0399614, 0.0399994, 0.0399999,
    0.0400006, 0.0400008, 0.0400016, 0.0400889, 0.0401539, 0.0402154, 0.0403448,
    0.040376, 0.0403844, 0.0405858, 0.040669, 0.0407124, 0.0407556, 0.0407667,
    0.0409168, 0.0409801, 0.0410119, 0.0410456, 0.0411069, 0.0411494, 0.0413468,
    0.0413939, 0.0414334, 0.0414735, 0.0415317, 0.0416341, 0.0418236, 0.0418982,
    0.042147, 0.0422039, 0.0422805, 0.042295, 0.042426, 0.0427848, 0.0429313,
    0.0429329, 0.0430577, 0.0430854, 0.0431201, 0.0431209, 0.0433035, 0.0433742,
    0.04344, 0.0434945, 0.0435598, 0.0435613, 0.0438122, 0.0439309, 0.0440647,
    0.0442027, 0.0443171, 0.0444401, 0.0445097, 0.04457, 0.0448224, 0.0449654,
    0.0452328, 0.0453089, 0.0453741, 0.045658, 0.0458054, 0.0461093, 0.0462148,
    0.0464131, 0.0464673, 0.0466022, 0.0467197, 0.046872, 0.0469721, 0.0471113,
    0.047223, 0.0472246, 0.0474295, 0.0476205, 0.0476348, 0.0477299, 0.0479823,
    0.0481922, 0.0482347, 0.0483836, 0.0483975, 0.048913, 0.0492332, 0.0492911,
    0.0495199, 0.0495408, 0.049996, 0.0500498, 0.0503029, 0.050428, 0.0506632,
    0.0507587, 0.0511873, 0.0514462, 0.0514968, 0.0515258, 0.0516867, 0.0520064,
    0.0520323, 0.0522089, 0.052323, 0.0525692, 0.0526682, 0.053051, 0.0530817,
    0.0533522, 0.0534599, 0.0537124, 0.0538138, 0.0538387, 0.0544955, 0.0545771,
    0.0545974, 0.0548551, 0.0549756, 0.0552576, 0.0553399, 0.0553544, 0.0557325,
    0.0559972, 0.0561026, 0.0561107, 0.0564009, 0.0564866, 0.0564889, 0.0568648,
    0.056866, 0.057243, 0.0575193, 0.0575442, 0.0576596, 0.0576708, 0.058227,
    0.0586869, 0.0591519, 0.0594212, 0.0595191, 0.0598301, 0.0602784, 0.0602929,
    0.0606566, 0.0609433, 0.0609734, 0.0616363, 0.0616614, 0.0621161, 0.062174,
    0.0625522, 0.0625719, 0.0628452, 0.0629304, 0.0632593, 0.0633296, 0.0633331,
    0.0633661, 0.0639335, 0.064402, 0.0644455, 0.0647472, 0.064848, 0.0655453,
    0.0655852, 0.0656067, 0.0659854, 0.0662692, 0.0663445, 0.0666885, 0.0667718,
    0.0673384, 0.0678312, 0.0678643, 0.0681712, 0.0682616, 0.0686235, 0.0686397,
    0.0689745, 0.0696096, 0.0700513, 0.0701172, 0.0701438, 0.0705377, 0.0712605,
    0.0712952, 0.0713151, 0.0717611, 0.0724031, 0.0724356, 0.0728033, 0.0731149,
    0.0735464, 0.0735754, 0.0739437, 0.0742553, 0.0746897, 0.0747287, 0.0752797,
    0.0758324, 0.0763174, 0.0764172, 0.0769751, 0.0769971, 0.077677, 0.077743,
    0.0781183, 0.0781374, 0.0788822, 0.0791984, 0.0792616, 0.0798308, 0.079855,
    0.0804043, 0.0807199, 0.0807807, 0.0807969, 0.0811751, 0.08154, 0.0815475,
    0.0815527, 0.0815562, 0.0821193, 0.0825896, 0.0826833, 0.0826868, 0.0826902,
    0.0830591, 0.0830638, 0.0834397, 0.083442, 0.0838178, 0.0838184, 0.0838335,
    0.0843003, 0.0849484, 0.0849762, 0.0853399, 0.0856677, 0.0857163, 0.0857221,
    0.0857245, 0.0861073, 0.0861096, 0.0861119, 0.0861194, 0.0864756, 0.0864947,
    0.0864971, 0.0864994, 0.0868098, 0.0868822, 0.0868845, 0.0868868, 0.0872343,
    0.0872627, 0.0872731, 0.0872766, 0.0872801, 0.0877876, 0.0878536, 0.087857,
    0.0878605, 0.0884054, 0.0884245, 0.0884268, 0.0884291, 0.0887123, 0.0887517,
    0.088812, 0.0888143, 0.0888166, 0.0891299, 0.0891994, 0.0892017, 0.0892041,
    0.0895081, 0.0895487, 0.0895869, 0.0895892, 0.0895915, 0.0895938, 0.089855,
    0.0898863, 0.0899767, 0.089979, 0.0899813, 0.0899836, 0.0899859, 0.0899882,
    0.0903711, 0.0903734, 0.0903757, 0.090378, 0.0903803, 0.0903826, 0.0906456,
    0.0906919, 0.0908021, 0.0908056, 0.0908091, 0.0908125, 0.090816, 0.0908195,
    0.091393, 0.0913964, 0.0913999, 0.0914034, 0.0914069, 0.0917201, 0.09176,
    0.0918346, 0.0919319, 0.0919342, 0.0919365, 0.0919389, 0.0919412, 0.092324,
    0.0923263, 0.0923286, 0.092331, 0.0923333, 0.0925435, 0.0927161, 0.0927184,
    0.0927207, 0.0929003, 0.0929779, 0.0931035, 0.0934841, 0.0936833, 0.0938628,
    0.0940429, 0.0941211, 0.0942364, 0.0942387, 0.094241, 0.094442, 0.0946076,
    0.0946099, 0.0946123, 0.0946146, 0.0948208, 0.0949719, 0.0949742, 0.0949766,
    0.0949789, 0.0949812, 0.0949835, 0.0949858, 0.0951989, 0.0952644, 0.0953432,
    0.0953455, 0.0953478, 0.0953501, 0.0955656, 0.0955771, 0.0960266, 0.0964829,
    0.0967082, 0.0967169, 0.0968594, 0.0968617, 0.0971698, 0.0972283, 0.0972306,
    0.097233, 0.0972353, 0.0972376, 0.097471, 0.0975996, 0.0976019, 0.0976042,
    0.0976065, 0.0978567, 0.0979708, 0.0979731, 0.0979754, 0.0979778, 0.0983131,
    0.0983374, 0.0983397, 0.0983421, 0.0983444, 0.0983467, 0.098349, 0.0986143,
    0.098616, 0.0987017, 0.098704, 0.0987063, 0.0987087, 0.098711, 0.0987133,
    0.0987156, 0.0990683, 0.0990706, 0.0990729, 0.0990753, 0.0990776, 0.0990799,
    0.0993753, 0.0994349, 0.0994372, 0.0994396, 0.0994419, 0.0994442, 0.0994465,
    0.0994564, 0.0997575, 0.0998015, 0.0998038, 0.0998062, 0.0998085, 0.0998108,
    0.0998131, 0.100134, 0.100175, 0.100177, 0.10018, 0.10051, 0.100513,
    0.10052, 0.1006, 0.100793, 0.100825, 0.101045, 0.101297, 0.10131, 0.101334,
    0.101362, 0.102353, 0.102387, 0.102442, 0.102505, 0.10269, 0.103215,
    0.10324, 0.103268, 0.103542, 0.103921, 0.104332, 0.104369, 0.104411,
    0.10459, 0.104843, 0.105095, 0.105121, 0.105146, 0.105174, 0.105434,
    0.105813, 0.106191, 0.106317, 0.106485, 0.106518, 0.106543, 0.106992,
    0.10708, 0.10738, 0.107418, 0.108088, 0.108223, 0.108423, 0.108448, 0.10864,
    0.108986, 0.109286, 0.109324, 0.109608, 0.110129, 0.11043, 0.110467,
    0.110748, 0.110891, 0.111092, 0.111302, 0.111371, 0.111654, 0.111805,
    0.112014, 0.112152, 0.112378, 0.112417, 0.112756, 0.113031, 0.113561,
    0.113761, 0.113787, 0.11397, 0.114323, 0.114479, 0.114524, 0.11455,
    0.114989, 0.115059, 0.115086, 0.115498, 0.11554, 0.115753, 0.115821,
    0.115849, 0.116009, 0.116264, 0.116302, 0.11652, 0.116521, 0.116584,
    0.116612, 0.117031, 0.117286, 0.117288, 0.117318, 0.117348, 0.117374,
    0.117543, 0.117798, 0.1178, 0.117857, 0.118137, 0.118266, 0.118268,
    0.118284, 0.118309, 0.118459, 0.118461, 0.118501, 0.118652, 0.118844,
    0.118883, 0.1189, 0.118991, 0.118992, 0.118998, 0.11912, 0.119121, 0.119121,
    0.119144, 0.11925, 0.119272, 0.119283, 0.119472, 0.119478, 0.119728,
    0.119729, 0.119772, 0.119985, 0.119986, 0.120045, 0.120193, 0.120222,
    0.120382, 0.120385, 0.120576, 0.120605, 0.120768, 0.120769, 0.120796,
    0.120809, 0.121012, 0.121256, 0.121267, 0.121302, 0.121523, 0.121524,
    0.121557, 0.121571, 0.121676, 0.121677, 0.121692, 0.121805, 0.121923,
    0.121933, 0.121948, 0.121954, 0.122114, 0.122136, 0.122306, 0.122307,
    0.122499, 0.1225, 0.122519, 0.12267, 0.122692, 0.122693, 0.122711, 0.122717,
    0.122829, 0.12283, 0.122958, 0.122968, 0.123086, 0.123099, 0.123291,
    0.123347, 0.123598, 0.123603, 0.123854, 0.123862, 0.123934, 0.123956,
    0.124051, 0.124148, 0.124242, 0.124244, 0.124245, 0.124435, 0.124748,
    0.124753, 0.125004, 0.125007, 0.125102, 0.125103, 0.125103, 0.125174,
    0.125199, 0.1252, 0.125295, 0.12539, 0.125392, 0.125393, 0.125393, 0.125394,
    0.125395, 0.125396, 0.125485, 0.125519, 0.125524, 0.125525, 0.125525,
    0.125526, 0.125527, 0.125528, 0.125772, 0.125775, 0.125788, 0.125913,
    0.126158, 0.126171, 0.126362, 0.126536, 0.126629, 0.126666, 0.126675,
    0.126918, 0.126928, 0.126988, 0.127016, 0.127119, 0.127301, 0.127315,
    0.127393, 0.127431, 0.127558, 0.127683, 0.127683, 0.127684, 0.127694,
    0.127778, 0.127778, 0.127848, 0.127873, 0.127886, 0.127967, 0.127968,
    0.128062, 0.128066, 0.128081, 0.128158, 0.128188, 0.128315, 0.128441,
    0.128448, 0.12846, 0.128612, 0.128634, 0.128729, 0.128824, 0.128831,
    0.128842, 0.128919, 0.128995, 0.129014, 0.129014, 0.129108, 0.129109,
    0.129129, 0.129203, 0.129214, 0.129214, 0.129214, 0.129214, 0.129215,
    0.129215, 0.129215, 0.129215, 0.129226, 0.129297, 0.12938, 0.129392,
    0.129583, 0.129598, 0.129609, 0.129667, 0.129774, 0.129896, 0.129954,
    0.129981, 0.129981, 0.129983, 0.129993, 0.130052, 0.130062, 0.130279,
    0.130339, 0.130365, 0.130366, 0.130366, 0.130367, 0.130367, 0.130368,
    0.130447, 0.130567, 0.130629, 0.13075, 0.130758, 0.130829, 0.130916,
    0.130949, 0.131044, 0.131133, 0.131134, 0.131134, 0.131134, 0.131135,
    0.131135, 0.131136, 0.131136, 0.131136, 0.131137, 0.131137, 0.131208,
    0.131237, 0.131311, 0.131332, 0.131495, 0.13152, 0.13152, 0.131521,
    0.131521, 0.131522, 0.131522, 0.131522, 0.131522, 0.131523, 0.131523,
    0.131523, 0.131525, 0.131525, 0.131525, 0.131525, 0.131526, 0.131527,
    0.131527, 0.131527, 0.131527, 0.131528, 0.131529, 0.131529, 0.131529,
    0.131529, 0.13153, 0.13153, 0.131531, 0.131531, 0.131531, 0.131531,
    0.131532, 0.131532, 0.131532, 0.131532, 0.131533, 0.131533, 0.131534,
    0.131534, 0.131534, 0.131534, 0.131534, 0.131534, 0.131535, 0.131535,
    0.131536, 0.131536, 0.131536, 0.131536, 0.131537, 0.131538, 0.131538,
    0.131538, 0.131538, 0.131539, 0.131539, 0.13154, 0.13154, 0.13154, 0.13154,
    0.131467, 0.131367, 0.131275, 0.131183, 0.131178, 0.131162, 0.131163,
    0.131163, 0.131164, 0.131164, 0.131164, 0.131165, 0.131165, 0.131166,
    0.131166, 0.131166, 0.131089, 0.131088, 0.130996, 0.130995, 0.130993,
    0.130807, 0.130803, 0.130788, 0.130712, 0.130708, 0.130618, 0.130518,
    0.130429, 0.130428, 0.13041, 0.13041, 0.13041, 0.130411, 0.130411, 0.130411,
    0.130412, 0.130412, 0.130412, 0.130413, 0.130414, 0.130414, 0.130414,
    0.130318, 0.130311, 0.130177, 0.130065, 0.130058, 0.130035, 0.130036,
    0.130036, 0.130037, 0.130038, 0.129967, 0.129954, 0.129865, 0.129683,
    0.12967, 0.12966, 0.12966, 0.129661, 0.129662, 0.129662, 0.129662, 0.129662,
    0.129552, 0.129303, 0.1293, 0.129283, 0.129212, 0.129107, 0.129014,
    0.128918, 0.128905, 0.128834, 0.128824, 0.128636, 0.128635, 0.128542,
    0.128526, 0.128527, 0.128527, 0.128528, 0.128528, 0.128529, 0.128529,
    0.128425, 0.128182, 0.128173, 0.128169, 0.12815, 0.128151, 0.128152,
    0.128152, 0.128152, 0.128075, 0.127988, 0.127883, 0.127799, 0.127774,
    0.127671, 0.127556, 0.127414, 0.127395, 0.127232, 0.127224, 0.127126,
    0.127043, 0.127017, 0.127018, 0.127018, 0.127018, 0.127019, 0.127019,
    0.126942, 0.126858, 0.126848, 0.126845, 0.126754, 0.126669, 0.12666,
    0.126641, 0.126541, 0.126534, 0.126427, 0.126416, 0.126415, 0.126291,
    0.126262, 0.126196, 0.126189, 0.126182, 0.126095, 0.126008, 0.126001,
    0.125884, 0.125788, 0.125787, 0.125777, 0.125671, 0.12566, 0.125659,
    0.125658, 0.125531, 0.12553, 0.12553, 0.125529, 0.125505, 0.12544, 0.125428,
    0.125427, 0.125426, 0.125426, 0.125425, 0.125331, 0.12533, 0.12533,
    0.125329, 0.125234, 0.125233, 0.125233, 0.125232, 0.125157, 0.125142,
    0.125137, 0.125136, 0.125136, 0.125127, 0.125127, 0.125127, 0.125127,
    0.125127, 0.125128, 0.125128, 0.125128, 0.125128, 0.125128, 0.125128,
    0.125129, 0.125129, 0.125129, 0.125129, 0.124998, 0.124997, 0.124996,
    0.124919, 0.124898, 0.124869, 0.124869, 0.124868, 0.124867, 0.12475,
    0.124743, 0.124742, 0.124741, 0.124741, 0.124688, 0.124646, 0.124645,
    0.124644, 0.124644, 0.124643, 0.124577, 0.124548, 0.124548, 0.124547,
    0.124546, 0.124499, 0.124451, 0.124451, 0.12445, 0.124372, 0.124355,
    0.124354, 0.124354, 0.124294, 0.124259, 0.124258, 0.124257, 0.124216,
    0.124162, 0.124162, 0.124068, 0.124068, 0.124027, 0.12401, 0.123994,
    0.123969, 0.123968, 0.123967, 0.123846, 0.123845, 0.123844, 0.123843,
    0.123786, 0.123722, 0.123721, 0.12372, 0.123637, 0.123615, 0.123603,
    0.123602, 0.123601, 0.123601, 0.123554, 0.123511, 0.12351, 0.12351,
    0.123509, 0.123509, 0.123419, 0.123419, 0.123418, 0.123417, 0.123417,
    0.123366, 0.123348, 0.123326, 0.123325, 0.123237, 0.12323, 0.123157,
    0.123107, 0.123106, 0.123105, 0.123104, 0.122984, 0.122983, 0.122983,
    0.122982, 0.122981, 0.122905, 0.12288, 0.12286, 0.122859, 0.122859,
    0.122858, 0.122766, 0.122766, 0.122704, 0.122673, 0.122673, 0.122672,
    0.122591, 0.122581, 0.12258, 0.12258, 0.122579, 0.122516, 0.122489,
    0.122489, 0.122488, 0.122487, 0.122487, 0.12248, 0.122422, 0.122397,
    0.122396, 0.122396, 0.122395, 0.122328, 0.122304, 0.122303, 0.122303,
    0.122213, 0.122211, 0.12221, 0.122139, 0.122101, 0.122102, 0.122103,
    0.122103, 0.121958, 0.121801, 0.121577, 0.121568, 0.121422, 0.121344,
    0.121246, 0.121017, 0.120993, 0.120988, 0.120966, 0.120967, 0.120967,
    0.120967, 0.120968, 0.120857, 0.120627, 0.120479, 0.120432, 0.120209,
    0.120108, 0.12001, 0.119852, 0.11983, 0.119754, 0.119681, 0.11947, 0.119468,
    0.119452, 0.119253, 0.119097, 0.119073, 0.118995, 0.118901, 0.11883,
    0.118735, 0.118711, 0.118695, 0.118591, 0.118494, 0.118316, 0.11826,
    0.118238, 0.118237, 0.118165, 0.118048, 0.117975, 0.117953, 0.117938,
    0.11788, 0.117764, 0.117596, 0.117575, 0.117574, 0.11756, 0.117355,
    0.117325, 0.117181, 0.117121, 0.117102, 0.117005, 0.116932, 0.116815,
    0.116803, 0.116721, 0.116697, 0.116594, 0.116566, 0.116467, 0.116424,
    0.116361, 0.116341, 0.116266, 0.116249, 0.116171, 0.116151, 0.116076,
    0.116075, 0.116056, 0.116046, 0.115958, 0.115939, 0.115806, 0.115701,
    0.115667, 0.115528, 0.115495, 0.115337, 0.115316, 0.115115, 0.114956,
    0.114908, 0.114813, 0.114792, 0.114686, 0.114674, 0.114559, 0.114539,
    0.114529, 0.114347, 0.114262, 0.11426, 0.114166, 0.114157, 0.114151,
    0.113977, 0.113967, 0.113882, 0.113881, 0.113786, 0.113778, 0.113773,
    0.113664, 0.113652, 0.113539, 0.113537, 0.113525, 0.11341, 0.113394,
    0.113311, 0.113303, 0.113219, 0.113216, 0.113113, 0.113026, 0.113024,
    0.113016, 0.112929, 0.112928, 0.112923, 0.112828,
  ],
}


export const dummyData =  {
  message: {
  "centerId": "TS09UC8429Bangalore",
      "patientId": "668bb0980fdc864c18306e58",
      "bedLabel": "TS09UC8429",
      "timeStamp": "2024-07-08T09:45:24.529Z",
      "device": "PhilipsMX550",
  "updatedVitals": [
  {
    "code": "Heart Rate",
    "value": "88",
    "measurementId": "bpm"
  },
  {
    "code": "RESP",
    "value": "[3456, 3526, 3601, 3661, 3702, 3709, 3681, 3610, 3507, 3394, 3274, 3130, 2970, 2821, 2680, 2526, 2357, 2162, 1948, 1738, 1527, 1280, 1006, 747, 500, 227, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 27, 150, 254, 343, 421, 484]",
    "measurementId": ""
  },
  {
    "code": "PLETH",
    "value": "[694, 795, 934, 1106, 1300, 1503, 1702, 1887, 2047, 2177, 2265, 2320, 2354, 2366, 2358, 2333, 2296, 2249, 2195, 2137, 2074, 2007, 1938, 1875, 1814, 1747, 1680, 1615, 1556, 1503, 1458, 1420, 1388, 1363, 1343, 1330, 1321, 1312, 1302, 1290, 1275, 1258, 1238, 1215, 1189, 1160, 1129, 1097, 1071, 1047, 1019, 987, 952, 916, 880, 845, 811, 884, 1012, 1061, 1077, 1078, 1065, 1051, 1044, 1053, 1088, 1153, 1251, 1380, 1533, 1699, 1867, 2009, 2127, 2239, 2333, 2402, 2446, 2466, 2465, 2447, 2417, 2379, 2334, 2289, 2244, 2191, 2134, 2073, 2011, 1949, 1887, 1828, 1775, 1728, 1687, 1651, 1625, 1605, 1585, 1567, 1551, 1536, 1522, 1508, 1492, 1474, 1455, 1434, 1417, 1400, 1378, 1351, 1321, 1290, 1258, 1227, 1197, 1166, 1135, 1103, 1073, 1050, 1032, 1009, 983, 954]",
    "measurementId": ""
  },
  {
    "code": "Pulse",
    "value": "",
    "measurementId": "bpm"
  },
  {
    "code": "SpO2",
    "value": "98.5",
    "measurementId": "%"
  },
  {
    "code": "ECG",
    "value": "[8217, 8203, 8203, 8215, 8221, 8210, 8196, 8198, 8211, 8217, 8208, 8197, 8200, 8212, 8214, 8201, 8193, 8201, 8217, 8216, 8197, 8178, 8179, 8191, 8188, 8175, 8192, 8250, 8327, 8395, 8412, 8373, 8320, 8274, 8232, 8201, 8192, 8202, 8211, 8202, 8186, 8183, 8194, 8199, 8190, 8178, 8180, 8192, 8196, 8184, 8172, 8177, 8192, 8197, 8187, 8179, 8185, 8197, 8197, 8183, 8174, 8182, 8197, 8197, 8183, 8175, 8183, 8193, 8189, 8175, 8171, 8183, 8196, 8193, 8179, 8173, 8184, 8195, 8190, 8177, 8176, 8189, 8199, 8189, 8175, 8177, 8193, 8201, 8193, 8182, 8187, 8206, 8215, 8208, 8199, 8205, 8218, 8222, 8211, 8201, 8206, 8218, 8218, 8206, 8198, 8206, 8219, 8219, 8206, 8199, 8209, 8222, 8220, 8206, 8201, 8213, 8226, 8222, 8210, 8207, 8218, 8226, 8219, 8206, 8207, 8221, 8230, 8222, 8209, 8208, 8220, 8225, 8214, 8202, 8206, 8219, 8221, 8209, 8200, 8205, 8217, 8218, 8205, 8196, 8203, 8216, 8216, 8205, 8197, 8203, 8213, 8213, 8203, 8198, 8209, 8222, 8220, 8206, 8200, 8210, 8222, 8221, 8208, 8203, 8211, 8217, 8206, 8192, 8194, 8209, 8214, 8201, 8185, 8186, 8201, 8206, 8194, 8182, 8186, 8199, 8201, 8188, 8181, 8191, 8206, 8208, 8197, 8188, 8192, 8198, 8189, 8172, 8163, 8172, 8197, 8231, 8271, 8333, 8396, 8400, 8345, 8275, 8230, 8218, 8213, 8198, 8183, 8183, 8197, 8204, 8192, 8178, 8180, 8195, 8203, 8194, 8182, 8185, 8198, 8203, 8192, 8181, 8186, 8198, 8199, 8188, 8179, 8186, 8198, 8197, 8182, 8173, 8183, 8196, 8194, 8181, 8175, 8187, 8200, 8196, 8182, 8177, 8187, 8197, 8191, 8176, 8173, 8185, 8196, 8189, 8176, 8178, 8191, 8199, 8192, 8180]",
    "measurementId": ""
  },
  {
    "code": "SYS",
    "value": "",
    "measurementId": "mmHg"
  },
  {
    "code": "Mean",
    "value": "",
    "measurementId": "mmHg"
  },
  {
    "code": "DIA",
    "value": "",
    "measurementId": "mmHg"
  }
]
}
}

export const monitorDummyData = {
  message: {
      "centerId": "TS09UC8429Bangalore",
      "patientId": "668bb0980fdc864c18306e58",
      "bedLabel": "TS09UC8429",
      "mrn": "RED_Y7O6Q0J6",
      "updatedVitals": [
          {
              "code": "Heart Rate",
              "value": "100",
              "measurementId": "bpm"
          },
          {
              "code": "PLETH",
              "value": [
                  1157,
                  1121,
                  1097,
                  1080,
                  1062,
                  1047,
                  1036,
                  1031,
                  1036,
                  1045,
                  1056,
                  1075,
                  1101,
                  1134,
                  1174,
                  1216,
                  1262,
                  1316,
                  1376,
                  1442,
                  1512,
                  1587,
                  1666,
                  1747,
                  1831,
                  1917,
                  2003,
                  2079,
                  2149,
                  2227,
                  2308,
                  2389,
                  2469,
                  2546,
                  2620,
                  2690,
                  2755,
                  2815,
                  2869,
                  2917,
                  2952,
                  2977,
                  3003,
                  3026,
                  3044,
                  3056,
                  3062,
                  3062,
                  3055,
                  3042,
                  3024,
                  3000,
                  2963,
                  2928,
                  2900,
                  2865,
                  2825,
                  2782,
                  2736,
                  2687,
                  2637,
                  2586,
                  2536,
                  2486
              ],
              "measurementId": ""
          },
          {
              "code": "Pulse",
              "value": "80",
              "measurementId": "bpm"
          },
          {
              "code": "SpO2",
              "value": "95",
              "measurementId": "%"
          },
          {
              "code": "ECG",
              "value": [
                  8181,
                  8181,
                  8181,
                  8182,
                  8182,
                  8182,
                  8182,
                  8182,
                  8182,
                  8182,
                  8182,
                  8182,
                  8182,
                  8180,
                  8177,
                  8175,
                  8174,
                  8175,
                  8175,
                  8175,
                  8175,
                  8175,
                  8176,
                  8176,
                  8176,
                  8176,
                  8176,
                  8176,
                  8176,
                  8176,
                  8177,
                  8177,
                  8177,
                  8177,
                  8177,
                  8177,
                  8177,
                  8177,
                  8178,
                  8178,
                  8178,
                  8178,
                  8178,
                  8178,
                  8178,
                  8178,
                  8179,
                  8179,
                  8179,
                  8179,
                  8179,
                  8180,
                  8180,
                  8180,
                  8180,
                  8180,
                  8180,
                  8180,
                  8180,
                  8180,
                  8180,
                  8180,
                  8181,
                  8181,
                  8181,
                  8181,
                  8181,
                  8181,
                  8181,
                  8181,
                  8182,
                  8182,
                  8182,
                  8182,
                  8182,
                  8182,
                  8182,
                  8182,
                  8183,
                  8183,
                  8183,
                  8183,
                  8183,
                  8183,
                  8183,
                  8183,
                  8184,
                  8184,
                  8184,
                  8184,
                  8184,
                  8184,
                  8184,
                  8184,
                  8185,
                  8185,
                  8185,
                  8185,
                  8185,
                  8185,
                  8185,
                  8185,
                  8185,
                  8186,
                  8188,
                  8200,
                  8222,
                  8250,
                  8278,
                  8303,
                  8328,
                  8352,
                  8365,
                  8357,
                  8329,
                  8298,
                  8270,
                  8244,
                  8218,
                  8194,
                  8178,
                  8175,
                  8177,
                  8178,
                  8178,
                  8178,
                  8178,
                  8178
              ],
              "measurementId": ""
          },
          {
              "code": "RESP",
              "value": [1181, 1227, 1277, 1330, 1386, 1445, 1507, 1572, 1640, 1708, 1777, 1844, 1910, 1974, 2037, 2098, 2157, 2212, 2263, 2310, 2351, 2385, 2413, 2436, 2453, 2463, 2467, 2466, 2459, 2445, 2425, 2397, 2363, 2323, 2278, 2227, 2172, 2114, 2053, 1988, 1921, 1850, 1776, 1702, 1628, 1555, 1483, 1413],
              "measurementId": ""
          },
          {
              "code": "SYS",
              "value": "100",
              "measurementId": "mmHg"
          },
          {
              "code": "Mean",
              "value": "80",
              "measurementId": "mmHg"
          },
          {
              "code": "DIA",
              "value": "60",
              "measurementId": "mmHg"
          }
      ],
      "timeStamp": "2024-07-08T09:45:24.529Z",
      "device": "PhilipsMX550"
  }
}
