export default {
    position: {
      relative: 'relative',
      absolute: 'absolute',
      fixed: 'fixed',
      static: 'static',
      sticky: 'sticky',
    },
    screens: {
      xs: '250px',
      sm: '480px',
      md: '768px',
      lg: '976px',
      xl: '1440px',
    },
    sizes: {
      xs: '15px',
      ys: '24px',
      sm: '30px',
      md: '45px',
      lg: '60px',
      xl: '75px',
      '2xl': '150px',
      xxxl: '220px',
    },
    spacing: {
      none: '0',
      auto: 'auto',
      px: '1px',
      '0': '0',
      '0.25': '0.064rem',
      '0.5': '0.125rem',
      '1': '0.25rem',
      '1.5': '0.375rem',
      '2': '0.5rem',
      '2.5': '0.625rem',
      '3': '0.75rem',
      '3.5': '0.875rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '6.5': '1.6rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '18': '4.5rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '75': '18.75rem',
      '80': '20rem',
      '90': '22rem',
      '96': '24rem',
      '-2px': '-2px',
      '-1px': '-1px',
      '-0.5': '-0.125rem',
      '-1': '-0.25rem',
      '-1.5': '-0.375rem',
      '-1.75': '-0.4rem',
      '-2': '-0.5rem',
      '-2.5': '-0.625rem',
      '-3': '-0.75rem',
      '-3.5': '-0.875rem',
      '-4': '-1rem',
      '-5': '-1.25rem',
      '-6': '-1.5rem',
      '-6.5': '-1.675rem',
      '-7': '-1.75rem',
      '-8': '-2rem',
      '-9': '-2.25rem',
      '-10': '-2.5rem',
      '-11': '-2.75rem',
      '-12': '-3rem',
      '-14': '-3.5rem',
      '-16': '-4rem',
      '-20': '-5rem',
      '-24': '-6rem',
      '-28': '-7rem',
      '-32': '-8rem',
      '-36': '-9rem',
      '-40': '-10rem',
      '-44': '-11rem',
      '-48': '-12rem',
      '-52': '-13rem',
      '-56': '-14rem',
      '-60': '-15rem',
      '-64': '-16rem',
      '-72': '-18rem',
      '-80': '-20rem',
      '-96': '-24rem',
      '60%': '60%',
      '65%': '65%',
      '20%': '20%',
      '30%': '30%',
      '50%': '50%',
      '70%': '70%',
      '80%': '80%',
      full: '100%',
      '22px': '22px',
      '75vw': '75vw',
      '80v': '80vw',
      '35vw': '35vw',
      '95vw': '95vw',
      '68vw': '68vw',
      '60vw': '60vw',
      '50vw': '50vw',
      '50vh': '50vh',
      '55vh': '55vh',
      '29vw':"30vw",
      '31vw': '31vw',
      '10%': '10%',
      '60vh': '60vh',
      '62vh': '62vh',
      '32vw': '33.2vw',
      '39vh': '39vh',
      '40vw': '39.5vw',
      '70vw': '69vw',
      '80vh': '80vh',
      '85vh': '85vh',
      '90vh': '90vh',
      'screen-width': '100vw',
      'screen-height': '100vh',
      'max-content': 'max-content',
      'fit': 'fit-content',
      'fill-available': '-webkit-fill-available'
    },
    borderRadius: {
      none: '0',
      sm: '.125rem',
      md: '.25rem',
      lg: '.5rem',
      xl: '8px',
      '2xl': '1rem',
      full: '9999px',
      rounded: '50vw',
      '10px': '10PX',
      '4px': '4px',
    },
    opacity: {
      '0': '0',
      '10': '0.1',
      '20': '0.2',
      '40': '0.4',
      '60': '0.6',
      '80': '0.8',
      '100': '1',
    },
    colors: {
      primary: '#581C87',
      primary1: '#F7F0FF',
      'primary-3': '#E9E4F3',
      'light-gray': '#e0e0e0',
      'primary-light': '#A66FF0',
      'gray-black': '#666666',
      white: '#ffffff',
      'white-6': '#F6F6F6',
      'white-off': '#F4F5F9',
      black: '#000000',
      orange: '#FF9B00',
      green: '#14AE5C',
      green1: '#2FE5A7',
      purple1: '#605CFF',
      'green-light': '#e7f7ef',
      blue: '#0052CC',
      'blue-light': '#e5eefa',
      red: '#E31E25',
      'red-4': '#DF231D',
      'blue-6': '#007FFF',
      'red-tart': '#FF7E86',
      'red-light': '#fef2f3',
      'gray-1': '#99B2C6',
      yellow: '#F6CC0D',
      'yellow-light': '#fefae7',
      bg: '#F7F0FF',
      'gray-dark': '#273444',
      disable: '#CCCCCC',
      'iphone-prompt': '#DDDDDD',
      'iphone-blue': '#418efc',
      'gray-light-opacity': '#788397',
      gray: '#8492a6',
      'gray-text': '#42526E',
      'gray-light': '#d3dce6',
      'gray-lighter': '#FAFAFA',
      'gray-ea': '#EAEAEA',
      'gray-4': '#444444',
      'gray-6': '#666666',
      'gray-light-6': '#687A8C',
      'gray-7': '#707070',
      'gray-9': '#999999',
      transparent: 'transparent',
      'bg-green': '#14AE5C',
      'bg-blue': '#0052CC',
      'bg-blue-1': '#cde1ff',
      'bg-black': '#111111',
      'black-4': '#373F48',
      'bg-red': '#D13731',
      'bg-red-light': '#FDECED',
      'bg-gray': '#999999',
      'bg-yellow': '#FFA200',
      'bg-pink-light': '#F1EDF3',
      'bg-pink': '#DACEE3',
      'bg-pink-thick': '#f9f7ff',
      'bg-gray-dark': '#364153',
      pink1: '#D99BFF',
      pink2: '#FF69B4',
      yellow1: '#F0C500',
      'blue-8': 'background: rgba(0, 127, 255, 0.2)',
      'red-50': '#ffebee',
      'red-100': '#ffcdd2',
      'red-200': '#ef9a9a',
      'red-300': '#e57373',
      'red-400': '#ef5350',
      'red-500': '#f44336',
      'red-600': '#e53935',
      'red-700': '#d32f2f',
      'red-800': '#c62828',
      'red-900': '#b71c1c',
      'red-100-accent': '#ff8a80',
      'red-200-accent': '#ff5252',
      'red-400-accent': '#ff1744',
      'red-700-accent': '#d50000',
      pink: '#e91e63',
      'pink-50': '#fce4ec',
      'pink-100': '#f8bbd0',
      'pink-200': '#f48fb1',
      'pink-300': '#f06292',
      'pink-400': '#ec407a',
      'pink-500': '#e91e63',
      'pink-600': '#d81b60',
      'pink-700': '#c2185b',
      'pink-800': '#ad1457',
      'pink-900': '#880e4f',
      'pink-100-accent': '#ff80ab',
      'pink-200-accent': '#ff4081',
      'pink-400-accent': '#f50057',
      'pink-700-accent': '#c51162',
      'pink-shiny': '#FB00C4',
      purple: '#9c27b0',
      'purple-50': '#f3e5f5',
      'purple-100': '#e1bee7',
      'purple-200': '#ce93d8',
      'purple-300': '#ba68c8',
      'purple-400': '#ab47bc',
      'purple-500': '#9c27b0',
      'purple-600': '#8e24aa',
      'purple-700': '#7b1fa2',
      'purple-800': '#6a1b9a',
      'purple-900': '#4a148c',
      'purple-bg': '#5247C6',
      'purple-100-accent': '#ea80fc',
      'purple-200-accent': '#e040fb',
      'purple-400-accent': '#d500f9',
      'purple-700-accent': '#aa00ff',
      'deep-purple': '#673ab7',
      'deep-purple-50': '#ede7f6',
      'deep-purple-100': '#d1c4e9',
      'deep-purple-200': '#b39ddb',
      'deep-purple-300': '#9575cd',
      'deep-purple-400': '#7e57c2',
      'deep-purple-500': '#673ab7',
      'deep-purple-600': '#5e35b1',
      'deep-purple-700': '#512da8',
      'deep-purple-800': '#4527a0',
      'deep-purple-900': '#311b92',
      'deep-purple-100-accent': '#b388ff',
      'deep-purple-200-accent': '#7c4dff',
      'deep-purple-400-accent': '#651fff',
      'deep-purple-700-accent': '#6200ea',
      'purple-blue': '#1d264d',
      indigo: '#3f51b5',
      'indigo-50': '#e8eaf6',
      'indigo-100': '#c5cae9',
      'indigo-200': '#9fa8da',
      'indigo-300': '#7986cb',
      'indigo-400': '#5c6bc0',
      'indigo-500': '#3f51b5',
      'indigo-600': '#3949ab',
      'indigo-700': '#303f9f',
      'indigo-800': '#283593',
      'indigo-900': '#1a237e',
      'indigo-100-accent': '#8c9eff',
      'indigo-200-accent': '#536dfe',
      'indigo-400-accent': '#3d5afe',
      'indigo-700-accent': '#304ffe',
      'blue-50': '#e3f2fd',
      'blue-100': '#bbdefb',
      'blue-200': '#90caf9',
      'blue-300': '#64b5f6',
      'blue-400': '#42a5f5',
      'blue-500': '#2196f3',
      'blue-600': '#1e88e5',
      'blue-700': '#1976d2',
      'blue-800': '#1565c0',
      'blue-900': '#0d47a1',
      'blue-dark': '#0223FF',
      'blue-100-accent': '#82b1ff',
      'blue-200-accent': '#448aff',
      'blue-400-accent': '#2979ff',
      'blue-700-accent': '#2962ff',
      'light-blue': '#03a9f4',
      'light-blue-50': '#e1f5fe',
      'light-blue-100': '#b3e5fc',
      'light-blue-200': '#81d4fa',
      'light-blue-300': '#4fc3f7',
      'light-blue-400': '#29b6f6',
      'light-blue-500': '#03a9f4',
      'light-blue-600': '#039be5',
      'light-blue-700': '#0288d1',
      'light-blue-800': '#0277bd',
      'light-blue-900': '#01579b',
      'light-blue-100-accent': '#80d8ff',
      'light-blue-200-accent': '#40c4ff',
      'light-blue-400-accent': '#00b0ff',
      'light-blue-700-accent': '#0091ea',
      cyan: '#00bcd4',
      'cyan-50': '#e0f7fa',
      'cyan-100': '#b2ebf2',
      'cyan-200': '#80deea',
      'cyan-300': '#4dd0e1',
      'cyan-400': '#26c6da',
      'cyan-500': '#00bcd4',
      'cyan-600': '#00acc1',
      'cyan-700': '#0097a7',
      'cyan-800': '#00838f',
      'cyan-900': '#006064',
      'cyan-100-accent': '#84ffff',
      'cyan-200-accent': '#18ffff',
      'cyan-400-accent': '#00e5ff',
      'cyan-700-accent': '#00b8d4',
      teal: '#009688',
      'teal-50': '#e0f2f1',
      'teal-100': '#b2dfdb',
      'teal-200': '#80cbc4',
      'teal-300': '#4db6ac',
      'teal-400': '#26a69a',
      'teal-500': '#009688',
      'teal-600': '#00897b',
      'teal-700': '#00796b',
      'teal-800': '#00695c',
      'teal-900': '#004d40',
      'teal-100-accent': '#a7ffeb',
      'teal-200-accent': '#64ffda',
      'teal-400-accent': '#1de9b6',
      'teal-700-accent': '#00bfa5',
      'green-50': '#e8f5e9',
      'green-dirty': '#D9D9D9',
      'green-100': '#c8e6c9',
      'green-200': '#a5d6a7',
      'green-300': '#81c784',
      'green-400': '#66bb6a',
      'green-500': '#4caf50',
      'green-600': '#43a047',
      'green-700': '#388e3c',
      'green-800': '#2e7d32',
      'green-900': '#1b5e20',
      'green-sky-blue': '#BCCCCC',
      'green-100-accent': '#b9f6ca',
      'green-200-accent': '#69f0ae',
      'green-400-accent': '#00e676',
      'green-700-accent': '#00c853',
      'green-800-accent': '#03645F',
      'light-green': '#8bc34a',
      'light-green-50': '#f1f8e9',
      'light-green-100': '#dcedc8',
      'light-green-200': '#c5e1a5',
      'light-green-300': '#aed581',
      'light-green-400': '#9ccc65',
      'light-green-500': '#8bc34a',
      'light-green-600': '#7cb342',
      'light-green-700': '#689f38',
      'light-green-800': '#558b2f',
      'light-green-900': '#33691e',
      'light-green-100-accent': '#ccff90',
      'light-green-200-accent': '#b2ff59',
      'light-green-400-accent': '#76ff03',
      'light-green-700-accent': '#64dd17',
      lime: '#cddc39',
      'lime-50': '#f9fbe7',
      'lime-100': '#f0f4c3',
      'lime-200': '#e6ee9c',
      'lime-300': '#dce775',
      'lime-400': '#d4e157',
      'lime-500': '#cddc39',
      'lime-600': '#c0ca33',
      'lime-700': '#afb42b',
      'lime-800': '#9e9d24',
      'lime-900': '#827717',
      'lime-100-accent': '#f4ff81',
      'lime-200-accent': '#eeff41',
      'lime-400-accent': '#c6ff00',
      'lime-700-accent': '#aeea00',
      'yellow-50': '#fffde7',
      'yellow-100': '#fff9c4',
      'yellow-200': '#fff59d',
      'yellow-300': '#fff176',
      'yellow-400': '#ffee58',
      'yellow-500': '#ffeb3b',
      'yellow-600': '#fdd835',
      'yellow-700': '#fbc02d',
      'yellow-800': '#f9a825',
      'yellow-900': '#f57f17',
      'yellow-100-accent': '#ffff8d',
      'yellow-200-accent': '#ffff00',
      'yellow-400-accent': '#ffea00',
      'yellow-700-accent': '#ffd600',
      amber: '#ffc107',
      'amber-50': '#fff8e1',
      'amber-100': '#ffecb3',
      'amber-200': '#ffe082',
      'amber-300': '#ffd54f',
      'amber-400': '#ffca28',
      'amber-500': '#ffc107',
      'amber-600': '#ffb300',
      'amber-700': '#ffa000',
      'amber-800': '#ff8f00',
      'amber-900': '#ff6f00',
      'amber-100-accent': '#ffe57f',
      'amber-200-accent': '#ffd740',
      'amber-400-accent': '#ffc400',
      'amber-700-accent': '#ffab00',
      'orange-50': '#fff3e0',
      'orange-100': '#ffe0b2',
      'orange-200': '#ffcc80',
      'orange-300': '#ffb74d',
      'orange-400': '#ffa726',
      'orange-500': '#ff9800',
      'orange-600': '#fb8c00',
      'orange-700': '#f57c00',
      'orange-800': '#ef6c00',
      'orange-900': '#e65100',
      'orange-100-accent': '#ffd180',
      'orange-200-accent': '#ffab40',
      'orange-400-accent': '#ff9100',
      'orange-700-accent': '#ff6d00',
      'deep-orange': '#ff5722',
      'deep-orange-50': '#fbe9e7',
      'deep-orange-100': '#ffccbc',
      'deep-orange-200': '#ffab91',
      'deep-orange-300': '#ff8a65',
      'deep-orange-400': '#ff7043',
      'deep-orange-500': '#ff5722',
      'deep-orange-600': '#f4511e',
      'deep-orange-700': '#e64a19',
      'deep-orange-800': '#d84315',
      'deep-orange-900': '#bf360c',
      'deep-orange-100-accent': '#ff9e80',
      'deep-orange-200-accent': '#ff6e40',
      'deep-orange-400-accent': '#ff3d00',
      'deep-orange-700-accent': '#dd2c00',
      brown: '#795548',
      'brown-50': '#efebe9',
      'brown-100': '#d7ccc8',
      'brown-200': '#bcaaa4',
      'brown-300': '#a1887f',
      'brown-400': '#8d6e63',
      'brown-500': '#795548',
      'brown-600': '#6d4c41',
      'brown-700': '#5d4037',
      'brown-800': '#4e342e',
      'brown-900': '#3e2723',
      grey: '#9e9e9e',
      'grey-50': '#fafafa',
      'grey-green': '#C7C7C7',
      'grey-100': '#f5f5f5',
      'grey-200': '#eeeeee',
      'grey-300': '#e0e0e0',
      'grey-400': '#bdbdbd',
      'grey-500': '#9e9e9e',
      'grey-600': '#757575',
      'grey-700': '#616161',
      'grey-800': '#424242',
      'grey-900': '#212121',
      'blue-grey': '#607d8b',
      'blue-grey-50': '#eceff1',
      'blue-grey-100': '#cfd8dc',
      'blue-grey-200': '#b0bec5',
      'blue-grey-300': '#90a4ae',
      'blue-grey-400': '#78909c',
      'blue-grey-500': '#607d8b',
      'blue-grey-600': '#546e7a',
      'blue-grey-700': '#455a64',
      'blue-grey-800': '#37474f',
      'blue-grey-900': '#263238',
      'red-contrast': 'white',
      'red-50-contrast': 'black',
      'red-100-contrast': 'black',
      'red-200-contrast': 'black',
      'red-300-contrast': 'black',
      'red-400-contrast': 'black',
      'red-500-contrast': 'white',
      'red-600-contrast': 'white',
      'red-700-contrast': 'white',
      'red-800-contrast': 'white',
      'red-900-contrast': 'white',
      'red-100-accent-contrast': 'black',
      'red-200-accent-contrast': 'white',
      'red-400-accent-contrast': 'white',
      'red-700-accent-contrast': 'white',
      'pink-contrast': 'white',
      'pink-50-contrast': 'black',
      'pink-100-contrast': 'black',
      'pink-200-contrast': 'black',
      'pink-300-contrast': 'black',
      'pink-400-contrast': 'black',
      'pink-500-contrast': 'white',
      'pink-600-contrast': 'white',
      'pink-700-contrast': 'white',
      'pink-800-contrast': 'white',
      'pink-900-contrast': 'white',
      'pink-100-accent-contrast': 'black',
      'pink-200-accent-contrast': 'white',
      'pink-400-accent-contrast': 'white',
      'pink-700-accent-contrast': 'white',
      'purple-contrast': 'white',
      'purple-50-contrast': 'black',
      'purple-100-contrast': 'black',
      'purple-200-contrast': 'black',
      'purple-300-contrast': 'white',
      'purple-400-contrast': 'white',
      'purple-500-contrast': 'white',
      'purple-600-contrast': 'white',
      'purple-700-contrast': 'white',
      'purple-800-contrast': 'white',
      'purple-900-contrast': 'white',
      'purple-100-accent-contrast': 'black',
      'purple-200-accent-contrast': 'white',
      'purple-400-accent-contrast': 'white',
      'purple-700-accent-contrast': 'white',
      'deep-purple-contrast': 'white',
      'deep-purple-50-contrast': 'black',
      'deep-purple-100-contrast': 'black',
      'deep-purple-200-contrast': 'black',
      'deep-purple-300-contrast': 'white',
      'deep-purple-400-contrast': 'white',
      'deep-purple-500-contrast': 'white',
      'deep-purple-600-contrast': 'white',
      'deep-purple-700-contrast': 'white',
      'deep-purple-800-contrast': 'white',
      'deep-purple-900-contrast': 'white',
      'deep-purple-100-accent-contrast': 'black',
      'deep-purple-200-accent-contrast': 'white',
      'deep-purple-400-accent-contrast': 'white',
      'deep-purple-700-accent-contrast': 'white',
      'indigo-contrast': 'white',
      'indigo-50-contrast': 'black',
      'indigo-100-contrast': 'black',
      'indigo-200-contrast': 'black',
      'indigo-300-contrast': 'white',
      'indigo-400-contrast': 'white',
      'indigo-500-contrast': 'white',
      'indigo-600-contrast': 'white',
      'indigo-700-contrast': 'white',
      'indigo-800-contrast': 'white',
      'indigo-900-contrast': 'white',
      'indigo-100-accent-contrast': 'black',
      'indigo-200-accent-contrast': 'white',
      'indigo-400-accent-contrast': 'white',
      'indigo-700-accent-contrast': 'white',
      'blue-contrast': 'white',
      'blue-50-contrast': 'black',
      'blue-100-contrast': 'black',
      'blue-200-contrast': 'black',
      'blue-300-contrast': 'black',
      'blue-400-contrast': 'black',
      'blue-500-contrast': 'white',
      'blue-600-contrast': 'white',
      'blue-700-contrast': 'white',
      'blue-800-contrast': 'white',
      'blue-900-contrast': 'white',
      'blue-100-accent-contrast': 'black',
      'blue-200-accent-contrast': 'white',
      'blue-400-accent-contrast': 'white',
      'blue-700-accent-contrast': 'white',
      'light-blue-contrast': 'white',
      'light-blue-50-contrast': 'black',
      'light-blue-100-contrast': 'black',
      'light-blue-200-contrast': 'black',
      'light-blue-300-contrast': 'black',
      'light-blue-400-contrast': 'black',
      'light-blue-500-contrast': 'white',
      'light-blue-600-contrast': 'white',
      'light-blue-700-contrast': 'white',
      'light-blue-800-contrast': 'white',
      'light-blue-900-contrast': 'white',
      'light-blue-100-accent-contrast': 'black',
      'light-blue-200-accent-contrast': 'black',
      'light-blue-400-accent-contrast': 'black',
      'light-blue-700-accent-contrast': 'white',
      'cyan-contrast': 'white',
      'cyan-50-contrast': 'black',
      'cyan-100-contrast': 'black',
      'cyan-200-contrast': 'black',
      'cyan-300-contrast': 'black',
      'cyan-400-contrast': 'black',
      'cyan-500-contrast': 'white',
      'cyan-600-contrast': 'white',
      'cyan-700-contrast': 'white',
      'cyan-800-contrast': 'white',
      'cyan-900-contrast': 'white',
      'cyan-100-accent-contrast': 'black',
      'cyan-200-accent-contrast': 'black',
      'cyan-400-accent-contrast': 'black',
      'cyan-700-accent-contrast': 'black',
      'teal-contrast': 'white',
      'teal-50-contrast': 'black',
      'teal-100-contrast': 'black',
      'teal-200-contrast': 'black',
      'teal-300-contrast': 'black',
      'teal-400-contrast': 'black',
      'teal-500-contrast': 'white',
      'teal-600-contrast': 'white',
      'teal-700-contrast': 'white',
      'teal-800-contrast': 'white',
      'teal-900-contrast': 'white',
      'teal-100-accent-contrast': 'black',
      'teal-200-accent-contrast': 'black',
      'teal-400-accent-contrast': 'black',
      'teal-700-accent-contrast': 'black',
      'green-contrast': 'white',
      'green-50-contrast': 'black',
      'green-100-contrast': 'black',
      'green-200-contrast': 'black',
      'green-300-contrast': 'black',
      'green-400-contrast': 'black',
      'green-500-contrast': 'white',
      'green-600-contrast': 'white',
      'green-700-contrast': 'white',
      'green-800-contrast': 'white',
      'green-900-contrast': 'white',
      'green-100-accent-contrast': 'black',
      'green-200-accent-contrast': 'black',
      'green-400-accent-contrast': 'black',
      'green-700-accent-contrast': 'black',
      'light-green-contrast': 'black',
      'light-green-50-contrast': 'black',
      'light-green-100-contrast': 'black',
      'light-green-200-contrast': 'black',
      'light-green-300-contrast': 'black',
      'light-green-400-contrast': 'black',
      'light-green-500-contrast': 'black',
      'light-green-600-contrast': 'black',
      'light-green-700-contrast': 'black',
      'light-green-800-contrast': 'white',
      'light-green-900-contrast': 'white',
      'light-green-100-accent-contrast': 'black',
      'light-green-200-accent-contrast': 'black',
      'light-green-400-accent-contrast': 'black',
      'light-green-700-accent-contrast': 'black',
      'lime-contrast': 'black',
      'lime-50-contrast': 'black',
      'lime-100-contrast': 'black',
      'lime-200-contrast': 'black',
      'lime-300-contrast': 'black',
      'lime-400-contrast': 'black',
      'lime-500-contrast': 'black',
      'lime-600-contrast': 'black',
      'lime-700-contrast': 'black',
      'lime-800-contrast': 'black',
      'lime-900-contrast': 'white',
      'lime-100-accent-contrast': 'black',
      'lime-200-accent-contrast': 'black',
      'lime-400-accent-contrast': 'black',
      'lime-700-accent-contrast': 'black',
      'yellow-contrast': 'black',
      'yellow-50-contrast': 'black',
      'yellow-100-contrast': 'black',
      'yellow-200-contrast': 'black',
      'yellow-300-contrast': 'black',
      'yellow-400-contrast': 'black',
      'yellow-500-contrast': 'black',
      'yellow-600-contrast': 'black',
      'yellow-700-contrast': 'black',
      'yellow-800-contrast': 'black',
      'yellow-900-contrast': 'black',
      'yellow-100-accent-contrast': 'black',
      'yellow-200-accent-contrast': 'black',
      'yellow-400-accent-contrast': 'black',
      'yellow-700-accent-contrast': 'black',
      'amber-contrast': 'black',
      'amber-50-contrast': 'black',
      'amber-100-contrast': 'black',
      'amber-200-contrast': 'black',
      'amber-300-contrast': 'black',
      'amber-400-contrast': 'black',
      'amber-500-contrast': 'black',
      'amber-600-contrast': 'black',
      'amber-700-contrast': 'black',
      'amber-800-contrast': 'black',
      'amber-900-contrast': 'black',
      'amber-100-accent-contrast': 'black',
      'amber-200-accent-contrast': 'black',
      'amber-400-accent-contrast': 'black',
      'amber-700-accent-contrast': 'black',
      'orange-contrast': 'black',
      'orange-50-contrast': 'black',
      'orange-100-contrast': 'black',
      'orange-200-contrast': 'black',
      'orange-300-contrast': 'black',
      'orange-400-contrast': 'black',
      'orange-500-contrast': 'black',
      'orange-600-contrast': 'black',
      'orange-700-contrast': 'black',
      'orange-800-contrast': 'white',
      'orange-900-contrast': 'white',
      'orange-100-accent-contrast': 'black',
      'orange-200-accent-contrast': 'black',
      'orange-400-accent-contrast': 'black',
      'orange-700-accent-contrast': 'black',
      'deep-orange-contrast': 'white',
      'deep-orange-50-contrast': 'black',
      'deep-orange-100-contrast': 'black',
      'deep-orange-200-contrast': 'black',
      'deep-orange-300-contrast': 'black',
      'deep-orange-400-contrast': 'black',
      'deep-orange-500-contrast': 'white',
      'deep-orange-600-contrast': 'white',
      'deep-orange-700-contrast': 'white',
      'deep-orange-800-contrast': 'white',
      'deep-orange-900-contrast': 'white',
      'deep-orange-100-accent-contrast': 'black',
      'deep-orange-200-accent-contrast': 'black',
      'deep-orange-400-accent-contrast': 'white',
      'deep-orange-700-accent-contrast': 'white',
      'brown-contrast': 'white',
      'brown-50-contrast': 'black',
      'brown-100-contrast': 'black',
      'brown-200-contrast': 'black',
      'brown-300-contrast': 'white',
      'brown-400-contrast': 'white',
      'brown-500-contrast': 'white',
      'brown-600-contrast': 'white',
      'brown-700-contrast': 'white',
      'brown-800-contrast': 'white',
      'brown-900-contrast': 'white',
      'grey-contrast': 'black',
      'grey-100-dim': 'rgba(56, 48, 52, 0.75)',
      'grey-50-contrast': 'black',
      'grey-100-contrast': 'black',
      'grey-200-contrast': 'black',
      'grey-300-contrast': 'black',
      'grey-400-contrast': 'black',
      'grey-500-contrast': 'black',
      'grey-600-contrast': 'white',
      'grey-700-contrast': 'white',
      'grey-800-contrast': 'white',
      'grey-900-contrast': 'white',
      'blue-grey-contrast': 'white',
      'blue-grey-50-contrast': 'black',
      'blue-grey-100-contrast': 'black',
      'blue-grey-200-contrast': 'black',
      'blue-grey-300-contrast': 'black',
      'blue-grey-400-contrast': 'white',
      'blue-grey-500-contrast': 'white',
      'blue-grey-600-contrast': 'white',
      'blue-grey-700-contrast': 'white',
      'blue-grey-800-contrast': 'white',
      'blue-grey-900-contrast': 'white',
      'primary-1': 'rgba(88, 28, 135, 0.1)',
      'gray-light-2': 'rgba(54, 65, 83, 0.2)'
    },
    zIndex: {
      'layer-1': 2000,
      'layer-2': 2100,
      'layer-3': 3200,
      'layer-4': 3500,
      'layer-5': 4500,
      'layer-6': 5000,
    },
    fontSize: {
      kks: '0.5rem',
      ks: '0.625rem',
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '3.75rem',
      '7xl': '4.5rem',
      '8xl': '6rem',
      '9xl': '8rem',
      '22px': '22px',
    },
    fontWeight: {
      thin: '100',
      extraLight: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    shadow: {
      sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      shadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
      md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      normal: '0 1px 10px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
      '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
      inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
      none: '0 0 #0000',
      all: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
    },
    fontFamily: {
      sans: ['Graphik', 'sans-serif'],
      serif: ['Merriweather', 'serif'],
    },
    cursor: {
      pointer: 'pointer',
      grab: 'grab',
      disabled: 'not-allowed',
      normal: 'normal',
    }
  }
  